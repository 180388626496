import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { config } from "../../config";

export class InstallmentPickerButtonRenderer extends InstallmentPickerRenderer {
    public render(container: HTMLElement) {
        container.classList.add("splitit-installment-picker-buttons");

        let buttons = this.options.map(o => {
            let widthPct = (100 / this.options.length).toFixed(2);

            return {
                numPayments: localizer.numPayments(o),
                amountPerMonth: this.getPaymentCalculationText(o),
                width: widthPct,
                isFirst: false,
                isLast: false
            };
        });

        buttons[0].isFirst = true;
        buttons[buttons.length - 1].isLast = true;

        let template = require('./buttons.t.html');
        container.innerHTML = template({ buttons });

        let buttonElements = container.querySelectorAll('button');
        buttonElements.forEach((b, idx) => b.addEventListener('click', ev => {
            this.setSelectedValue(this.options[idx]);
            buttonElements.forEach(b2 => b2.classList.remove('selected'));
            b.classList.add('selected');
        }));

        if (this.initialSelection){
            var idx = this.options.findIndex(val => val == this.initialSelection);
            if (idx > -1){
                buttonElements[idx].click();
            }
        }
    }

    protected getPickerType() : string{
        return "buttons";
    }
}