import { FIELD_TYPES } from "./field-types";
import { FlexField } from "./field-base";
import { CardholderNameField } from "./cardholder";
import { CardNumberField } from "./cardnumber";
import { ExpirationDateField } from "./expiration-date";
import { CvvField } from "./cvv";
import { ExpirationMonthField } from "./expiration-month";
import { ExpirationYearField } from "./expiration-year";

export class FlexFieldFactory {
    public static create(type: string, guid: string, uiMode?: string) : FlexField {
        switch(type){
            case FIELD_TYPES.CARDHOLDER_NAME:
                return new CardholderNameField(guid, uiMode);
            case FIELD_TYPES.NUMBER:
                return new CardNumberField(guid, uiMode);
            case FIELD_TYPES.EXP_DATE:
                return new ExpirationDateField(guid, uiMode);
            case FIELD_TYPES.EXP_MONTH:
                return new ExpirationMonthField(guid);
            case FIELD_TYPES.EXP_YEAR:
                return new ExpirationYearField(guid);
            case FIELD_TYPES.CVV:
                return new CvvField(guid, uiMode);
        }
    }
}