import { state } from "../../state";
import { frameManager, MessageTypes } from "../../frame-manager";
import { TrackerEventPayload } from "../../frame-manager-payloads";
import { localizer } from "../../localization-provider";
import { config } from "../../config";

export interface IPaymentCalculation {
    amountNow: number,
    monthly: number
}

export class InstallmentPickerRenderer{
    protected options: Array<number>;
    protected amount: number;
    protected initialSelection?: number;

    constructor(amount: number, opts: Array<number>, initialSelection?: number){
        this.options = opts;
        this.amount = amount;
        this.initialSelection = initialSelection;
    }

    public render(container: HTMLElement) {
        throw new Error("Method render() not implemented in type: " + this.constructor.name);
    }

    protected getPaymentCalculation(numInstallments: number){
        let firstAmount = state.get().firstInstallmentAmount ?? 0;
        let monthlyValue = firstAmount > 0 && numInstallments > 1 
            ? (this.amount - firstAmount) / (numInstallments - 1) 
            : this.amount / numInstallments;

        return <IPaymentCalculation>{
            amountNow: numInstallments > 1 ? firstAmount : 0,
            monthly: monthlyValue
        }
    }

    protected getPaymentCalculationText(numInstallments: number){
        let paymentCalc = this.getPaymentCalculation(numInstallments);

        let amountNowText = paymentCalc.amountNow ? localizer.amountNow(state.getCurrencySymbol(), paymentCalc.amountNow, state.getCurrencyDecimalPlaces()) : '';
        return amountNowText + localizer.amountPerMonth(state.getCurrencySymbol(), paymentCalc.monthly, state.getCurrencyDecimalPlaces());
    }

    protected setSelectedValue(newValue){
        let oldValue = state.get().numInstallments;
        state.setNumInstallments(newValue);

        if (oldValue != newValue){
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.installmentPickerChanged({
                oldValue, 
                newValue,
                options: this.options,
                pickerType: this.getPickerType()
            }));
        }
    }

    protected getPickerType() : string{
        return "unknown";
    }
}