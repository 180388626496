import { CollectCardDataPayload, TrackerEventPayload } from "../frame-manager-payloads";
import { state } from "../state";
import { FIELD_TYPES } from "./field-types";
import { localizer } from "../localization-provider";
import { ModelError } from "splitit-sdk";
import * as splititApi from 'splitit-sdk';
import { utils } from "../utils";
import { ApiErrorParser } from "../api-error-parser";
import { FieldDescriptorInfo } from "../ui-components/field-descriptor";
import { apiFactory } from "../api-factory";
import { ActiveData, Amount, Currency, ThreeDsTwoTokenizer, EventFactory, ISplititError } from "splitit-utils";
import { frameManager, MessageTypes } from "../frame-manager";
import { splititTrackerDecorator } from "../tracking/splitit-tracker-decorator";

export class FieldValidationManager {
    private _originalField: FieldDescriptorInfo;
    private _payload: CollectCardDataPayload;
    private _otherValues: any;
    private static _errorCodeFieldMap: any;
    private _3ds2Tokenizer?: ThreeDsTwoTokenizer;

    constructor(originalField: FieldDescriptorInfo, payload: CollectCardDataPayload, otherValues: any, tokenizer3ds2: ThreeDsTwoTokenizer){
        this._originalField = originalField;
        this._payload = payload;
        this._otherValues = otherValues;  
        this._3ds2Tokenizer = tokenizer3ds2;      

        if (!FieldValidationManager._errorCodeFieldMap){
            FieldValidationManager._errorCodeFieldMap = FieldValidationManager.initErrorCodeMap();
        }
    }

    isSeparateExpMonthConsideredInvalid(){
        if (this._originalField.type == FIELD_TYPES.EXP_MONTH && 
            this._otherValues[FIELD_TYPES.EXP_YEAR] && 
            this._otherValues[FIELD_TYPES.EXP_YEAR].length == 2){
                let m = parseInt(this._payload.value);
                let y = parseInt(this._otherValues[FIELD_TYPES.EXP_YEAR]);

                let curM = new Date().getMonth() + 1;
                let curY = new Date().getFullYear() % 100;
                if (y < curY){
                    return false;
                }

                if (y * 100 + m < curY * 100 + curM){
                    return true;
                }
        }

        return false;
    }

    validate(callback: (result: FieldDescriptorInfo, serverErrors?: Array<ModelError>) => void) {
        try{
            let result = Object.assign({}, this._originalField);
            result.showValidationError = this._payload.showValidationError;
            result.isFocused = this._payload.isFocused;
            result.isValid = this._payload.isValid;
            result.hasContent = this._payload.value && this._payload.value.length > 0 ? true : false;
            result.cardType = this._payload.cardType;
            result.disabled = this._payload.isDisabled;
            result.errors = [];
    
            if (this._payload.clientError){
                result.errors.push(this._payload.clientError);
            }
    
            if (this._originalField.type == FIELD_TYPES.EXP_MONTH && 
                this._payload.isValid != false && 
                this.isSeparateExpMonthConsideredInvalid()){
                    result.isValid = false;
                    result.errors.push(localizer.invalidExpirationDate);
            }
    
            let requireServerSideValidation = this._payload.requireServerSideValidation;
            if (result.isValid === false){
                requireServerSideValidation = false;
            }
    
            if (this._payload.commitId){
                requireServerSideValidation = false;
            }
    
            let numberField = state.getField(FIELD_TYPES.NUMBER);
            if (this._originalField.type != FIELD_TYPES.NUMBER) {
                if (numberField.isValid === false){
                    requireServerSideValidation = false;
                }
                if (!this._otherValues[FIELD_TYPES.NUMBER] || this._otherValues[FIELD_TYPES.NUMBER] == ''){
                    requireServerSideValidation = false;
                }
            }
    
            if (!state.get().ipn || !state.get().publicToken){
                requireServerSideValidation = false;
            }
    
            if (!requireServerSideValidation){
                callback.call(this, result, null);
            } else {
                // Perform server side validation...
                let cardData = utils.parseCardData(this._otherValues);
                
                const requestData: splititApi.InitiateInstallmentPlanRequest = {
                    creditCardDetails: cardData,
                    installmentPlanNumber: state.get().ipn,
                    planData: <splititApi.PlanData>{
                        numberOfInstallments: state.get().numInstallments
                    }
                };
        
                let _this = this;
                let installmentPlanApi = apiFactory.getPlanApi();
                
                installmentPlanApi.installmentPlanInitiate(<splititApi.InstallmentPlanInitiateRequest>{ request: requestData })
                    .then(serverResult => {
                        if (serverResult.installmentPlan && serverResult.installmentPlan.strategy){
                            state.setStrategy(serverResult.installmentPlan.strategy);
                        }
    
                        if (serverResult && serverResult.responseHeader && !serverResult.responseHeader.succeeded) {
                            // This shouldn't happen since SDK throws error if responseHeader is not succeeeded, but just in case SDK changes at some point.
                            callback.call(_this, result, serverResult.responseHeader.errors);
                        } else {
                            result.isValid = true;

                            if (requestData.planData.numberOfInstallments && 
                                requestData.creditCardDetails &&
                                requestData.creditCardDetails.cardNumber && requestData.creditCardDetails.cardNumber.length > 1 &&
                                requestData.creditCardDetails.cardExpMonth && requestData.creditCardDetails.cardExpMonth.length > 1 &&
                                requestData.creditCardDetails.cardExpYear && requestData.creditCardDetails.cardExpYear.length > 1 &&
                                requestData.creditCardDetails.cardCvv && requestData.creditCardDetails.cardCvv.length > 1){
                                    splititTrackerDecorator.sendEvent(EventFactory.ServerCardValidationSuccess(TrackerEventPayload.getUiStateInfo()));
                            }

                            if (this._3ds2Tokenizer){
                                var data = <ActiveData>{};
                                data.Amount = <Amount>{};
                                data.Amount.CurrencyCode = serverResult.installmentPlan.amount.currency?.code;
                                data.Amount.Value = serverResult.installmentPlan.amount.value;
                                data.Amount.Currency = <Currency>{
                                    Code: serverResult.installmentPlan.amount.currency?.code,
                                    Description: serverResult.installmentPlan.amount.currency?.description,
                                    Id: serverResult.installmentPlan.amount.currency?.id,
                                    Symbol: serverResult.installmentPlan.amount.currency?.symbol
                                };

                                data.Bin = serverResult.installmentPlan.activeCard.bin;
                                data.CardCvv = requestData.creditCardDetails.cardCvv;
                                data.CardExpMonth = requestData.creditCardDetails.cardExpMonth;
                                data.CardExpYear = requestData.creditCardDetails.cardExpYear;
                                data.CardNumber = requestData.creditCardDetails.cardNumber;

                                this._3ds2Tokenizer.GetFingerPrint(data);
                            }

                            callback.call(_this, result, []);
                        }
                    }).catch(err => {
                        utils.trace(err);
                        result.isValid = null;
    
                        let errorParser = new ApiErrorParser(err);
                        let serverErrors = errorParser.parse(false);
    
                        if (!FieldValidationManager._errorCodeFieldMap[result.type]){
                            FieldValidationManager._errorCodeFieldMap[result.type] = [];
                        }

                        if (this._originalField.type == FIELD_TYPES.EXP_MONTH && 
                            !this.isSeparateExpMonthConsideredInvalid()){
                            result.isValid = null;
                        } else {
                            if (serverErrors.filter(e => FieldValidationManager._errorCodeFieldMap[result.type].includes(e.errorCode)).length > 0){
                                result.isValid = false;
                            } else {
                                result.isValid = true;
                            }
                        }
                        
                        //console.log(`Server validation complete for ${result.type}, verdict: valid=${result.isValid}`);
                        callback.call(_this, result, serverErrors);
                    });
            }
        } catch (e){
            utils.logException(e);
        }
    }

    private static initErrorCodeMap(){
        let obj = {};
        obj[FIELD_TYPES.NUMBER] = [
            '541', '521', '542', '504', '5041', '5042', 
            '528', '564', '568', '580', '5801', '5802', 
            '594', '607', '609', '610'];

        obj[FIELD_TYPES.CVV] = ['520', '603'];

        obj[FIELD_TYPES.CARDHOLDER_NAME] = ['510'];

        obj[FIELD_TYPES.EXP_DATE] = ['522', '540', '569', '570', '581', '604'];
        obj[FIELD_TYPES.EXP_YEAR] = ['522', '540', '570', '604', '581'];
        obj[FIELD_TYPES.EXP_MONTH] = ['522', '540', '569', '604', '581'];

        return obj;
    }
}