import { state, IPartialSplititState } from "../state";
import * as splititApi from 'splitit-sdk';
import { apiFactory } from "../api-factory";
import { ApiErrorParser } from "../api-error-parser";
import { utils } from "../utils";
import { config } from "../config";
import { Currency } from "splitit-sdk";

export interface IPlanData{
    options: Array<number>,
    ui: string,
    amount: number,
    currencyCode: string,
    strategy: any,
    ipn: string,
    privacyPolicyUrl: string,
    learnMoreUrl: string,
    termsAndConditionsUrl: string,
    firstChargeDate?: Date,
    firstInstallmentAmount?: number
    selectedNumInstallments?: number;
    currencyDisplay?: splititApi.ExtendedCurrency;
    is3ds2Supported: boolean;
    processorName?: string;
    refOrderNumber?: string;
    errorRedirectUrl?: string;
    successRedirectUrl?: string;
};

class PlanStore {

    private _planData?: IPlanData;
    private _listeners: Array<(data: IPlanData) => void>;

    private _scheduleData?: splititApi.GetInstallmentsScheduleResponse;
    private _scheduleListeners: Array<(data: splititApi.GetInstallmentsScheduleResponse) => void>;

    public init(){
        this._listeners = [];
        this._planData = null;

        this._scheduleListeners = [];
        this._scheduleData = null;
    }

    public onLoaded(callback: (data:IPlanData) => void) : void {
        this._listeners.push(callback);

        if (this._planData != null){
            callback(this._planData);
        }
    }

    public onScheduleLoaded(callback: (data: splititApi.GetInstallmentsScheduleResponse) => void){
        this._scheduleListeners.push(callback);

        if (this._scheduleData != null){
            callback(this._scheduleData);
        }
    }

    public load(){
        let installmentPlanApi = apiFactory.getPlanApi();

        const requestData: splititApi.GetInitiatedInstallmentPlanRequest = { };
        const schedulesRequestData: splititApi.GetInstallmentSchedulesRequest = {};

        Promise.all([
            installmentPlanApi.installmentPlanGetInitiatedInstallmentPlanRequest(<splititApi.InstallmentPlanGetInitiatedInstallmentPlanRequestRequest>{ request: requestData }), 
            installmentPlanApi.installmentPlanGetSchedules(<splititApi.InstallmentPlanGetSchedulesRequest>{ request: schedulesRequestData })
        ]).then(results => {
                let installmentPlanResult = results[0];
                let schedulesResult = results[1];

                if (installmentPlanResult && installmentPlanResult.responseHeader && !installmentPlanResult.responseHeader.succeeded) {
                    // This shouldn't happen since SDK throws error if responseHeader is not succeeeded, but just in case SDK changes at some point.
                    state.setErrors(installmentPlanResult.responseHeader.errors);
                } else {
                    this._planData = <IPlanData>{
                        options: installmentPlanResult.paymentWizardDataResponse.requestedNumberOfInstallments.split(',').map(p => parseInt(p)),
                        ui: installmentPlanResult.paymentWizardDataResponse.numberOfInstallmentsSelectionsOption,
                        is3ds2Supported: installmentPlanResult.paymentWizardDataResponse.is3ds2Supported,
                        processorName: installmentPlanResult.paymentWizardDataResponse.processorName,
                        amount: installmentPlanResult.planData.amount.value,
                        currencyCode: installmentPlanResult.planData.amount.currencyCode,
                        strategy: installmentPlanResult.planData.strategy,
                        privacyPolicyUrl: installmentPlanResult.paymentWizardDataResponse.privacyPolicyUrl,
                        learnMoreUrl: installmentPlanResult.paymentWizardDataResponse.learnMoreUrl,
                        termsAndConditionsUrl: installmentPlanResult.paymentWizardDataResponse.termsAndConditionsUrl,
                        ipn: installmentPlanResult.installmentPlanNumber,
                        firstChargeDate: installmentPlanResult.planData.firstChargeDate,
                        firstInstallmentAmount: installmentPlanResult.planData.firstInstallmentAmount ? installmentPlanResult.planData.firstInstallmentAmount.value : null,
                        selectedNumInstallments: installmentPlanResult.planData.numberOfInstallments == 0 ? null : installmentPlanResult.planData.numberOfInstallments,
                        currencyDisplay: installmentPlanResult.paymentWizardDataResponse.currencyDisplay,
                        refOrderNumber: installmentPlanResult.planData.refOrderNumber,
                        errorRedirectUrl: installmentPlanResult.paymentWizardDataResponse.cancelExitURL,
                        successRedirectUrl: installmentPlanResult.paymentWizardDataResponse.successExitURL
                    }

                    this._scheduleData = schedulesResult;

                    state.setPartial(<IPartialSplititState>{
                        amount: this._planData.amount,
                        is3ds2Supported: this._planData.is3ds2Supported,
                        processorName: this._planData.processorName,
                        currencyCode: this._planData.currencyCode,
                        strategy: this._planData.strategy,
                        privacyPolicyUrl: this._planData.privacyPolicyUrl,
                        termsConditionsUrl: this._planData.termsAndConditionsUrl,
                        ipn: this._planData.ipn,
                        firstChargeDate: this._planData.firstChargeDate,
                        firstInstallmentAmount: this._planData.firstInstallmentAmount,
                        currencyDecimalPlaces: this._planData.currencyDisplay?.decimalPlaces ?? 2,
                        refOrderNumber: this._planData.refOrderNumber,
                        errorRedirectUrl: this._planData.errorRedirectUrl,
                        successRedirectUrl: this._planData.successRedirectUrl
                    });

                    this._listeners.forEach(f => f(this._planData));
                    this._scheduleListeners.forEach(f => f(this._scheduleData));
                }
        }).catch(err => {
                let errorParser = new ApiErrorParser(err);
                errorParser.parse(true);
                utils.logException(err, false);
        });
    }
}

const planStore = new PlanStore();
export {planStore};