import { FLEX_FIELDS_URL } from '../constants';

export const FIELD_TYPES = {
    CVV: "cvv",
    NUMBER: "number",
    CARDHOLDER_NAME: "cardholder-name",
    EXP_DATE: "expiration-date",
    EXP_MONTH: "expiration-month",
    EXP_YEAR: "expiration-year"
};

export function getFieldUrlForType(type) {
    return FLEX_FIELDS_URL + "formfield.html?type=" + type;
}