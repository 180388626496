import {VERSION, FLEX_FIELDS_ENV} from './constants';
import { config } from './config';
import { localizer } from './localization-provider';
import { Get3DSecureParametersResponse } from 'splitit-sdk';
import { env, ThreeDsIframe } from 'splitit-utils';

export function appendHiddenFrame(container: HTMLElement, url: string, frameName): void {
    if (url.indexOf('?') > 0){
        url += '&v=' + VERSION;
    } else {
        url += '?v=' + VERSION;
    }

    var frm = document.createElement('iframe');
    frm.src = url;
    frm.style.display = "none";
    frm.name = frameName;
    container.appendChild(frm);
}

export function renderLoader(container: HTMLElement){
    if (config.loader){
        if (config.loader instanceof Function){
            config.loader(container);
        } else {
            let loaderStr = `${config.loader}`;
            try{
                if (document.querySelector(loaderStr)){
                    container.append(document.querySelector(loaderStr));
                } else {
                    container.innerHTML = `<div class="splitit-loader-container">${loaderStr}</div>`;
                }
            } catch(e){
                container.innerHTML = `<div class="splitit-loader-container">${loaderStr}</div>`;
            }
        }
    } else {
        let template = require('./ui-components/loader.t.html');
        container.innerHTML = template({});
    }
}

export interface IModalParams {
    title: string;
    content: string;
    cancel?: string;
    ok: string;

    okCallback?: () => void;
    cancelCallback?: () => void;
}


export function renderSimpleModal(modalParams: IModalParams){
    let template = require('./ui-components/modal.t.html');
    
    var div = document.createElement('div');
    div.innerHTML = template(modalParams);

    var modalWrapper = document.body.appendChild(div.firstChild) as HTMLElement;

    const cancelAction = () => {
        modalWrapper.classList.remove('splitit-modal-open');

        if(modalParams.cancelCallback){
            modalParams.cancelCallback();
        }

        setTimeout(() => modalWrapper.remove(), 500);
    }

    modalWrapper.onclick = cancelAction;

    var modalInner = modalWrapper.querySelector('.splitit-modal') as HTMLElement;
    modalInner.onclick = (ev) => {
        ev.stopPropagation();
    }

    setTimeout(() => modalWrapper.classList.add('splitit-modal-open'), 100);

    let closeBtn = modalWrapper.querySelector('.splitit-btn-close') as HTMLAnchorElement;
    closeBtn.onclick = cancelAction;

    let okBtn = modalWrapper.querySelector('.splitit-btn-ok') as HTMLButtonElement;
    okBtn.onclick = () => {
        if(modalParams.okCallback){
            modalParams.okCallback();
        }

        modalWrapper.classList.remove('splitit-modal-open');
        setTimeout(() => modalWrapper.remove(), 500);
    };

    let cancelBtn = modalWrapper.querySelector('.splitit-btn-cancel') as HTMLButtonElement;
    cancelBtn.onclick = cancelAction;
}

export interface IModal3dsIframeParams {
    publicToken: string;
    onComplete: (result: IModal3dsIframeResult) => void;
    onUserClosed: () => void;
}

export interface IModal3dsIframeResult {
    isSuccess: boolean;
    redirectUrl?: string;
}

export function hide3dModal(wrapper: HTMLElement){
    wrapper.remove();
}

export function render3dModalIframe(modalParams: IModal3dsIframeParams) : HTMLElement {
    let template = require('./ui-components/modal-3ds1-iframe.t.html');
    
    var div = document.createElement('div');
    div.innerHTML = template({
        localizer: localizer
    });

    var modalWrapper = document.body.appendChild(div.firstChild) as HTMLElement;
    setTimeout(() => modalWrapper.classList.add('splitit-modal-open'), 100);

    var iframeContainer = modalWrapper.querySelector(".iframe-container");
    iframeContainer.id = `splitit-3ds2-${new Date().getTime()}`;

    var ffEnv = <env>(FLEX_FIELDS_ENV == 'local' ? 'sandbox' : FLEX_FIELDS_ENV);
    var iframe3ds = new ThreeDsIframe({
        Identifier: iframeContainer.id, 
        Env: ffEnv, 
        PublicToken: modalParams.publicToken,
        OnSuccessCb: (event) => modalParams.onComplete({
            isSuccess: event.isSuccess,
            redirectUrl: event.redirectUrl
        }), 
        OnFailureCb: (err?) => modalParams.onComplete({
            isSuccess: false
        })
    });

    let closeBtn = modalWrapper.querySelector('.splitit-btn-close') as HTMLAnchorElement;
    closeBtn.onclick = () => {
        iframe3ds.dispose();
        modalWrapper.classList.remove('splitit-modal-open');
        setTimeout(() => {
            modalWrapper.remove();

            if (modalParams.onUserClosed){
                modalParams.onUserClosed();
            }
        }, 500);
    };

    return modalWrapper;
}