import { frameManager, MessageTypes } from "../frame-manager";
import { TrackerEventPayload, TrackerInitPayload } from "../frame-manager-payloads";
import { state } from "../state";
import { FLEX_FIELDS_ENV, DATA_TRACKING_ENDPOINT, VERSION } from '../constants.js';
import { EventFactory, ISplititSessionInfo, ITrackingEvent, IAdditionalInfo, IUIStateInfo, SplititTracker } from "splitit-utils";
import { ErrorMapper } from "../error-mapper";
import { FIELD_TYPES } from "../fields/field-types";

export class SplititTrackerDecorator {
    private _firstInteractionRecorded: boolean;
    private _isPlanInitialized: boolean;
    private _tracker: SplititTracker;
    private _prevEvent?: ITrackingEvent;

    constructor() {
        this._firstInteractionRecorded = false;
        this._isPlanInitialized = false;
        this._tracker = new SplititTracker(DATA_TRACKING_ENDPOINT);
    }

    init() {

        state.onChange(() => {
            try{
                if (!this._isPlanInitialized) {
                    if (state.get().ipn && state.get().publicToken) {
                        this._isPlanInitialized = true;
                        this._tracker.updateSessionInfo(<ISplititSessionInfo>{
                            planNumber: state.get().ipn,
                            publicToken: state.get().publicToken
                        });
                    }
                }
            } catch(ex){
                console.error(ex);
            }
        });

        frameManager.subscribe(MessageTypes.TRACKER_INIT, this, (data: TrackerInitPayload) => {
            this._tracker.updateSessionInfo(<ISplititSessionInfo>{
                env: FLEX_FIELDS_ENV,
                version: VERSION,
                product: "FlexFields",
                pageUrl: data.merchantUrl
            });

            this._tracker.recordEvent(EventFactory.LoadComplete());
        });

        frameManager.subscribe(MessageTypes.TRACKER_EVENT, this, (data: TrackerEventPayload) => {
            if (data.event == 'user-interaction') {
                if (this._firstInteractionRecorded) {
                    return;
                }
                this._firstInteractionRecorded = true;
            }

            data.eventData = this.extendAdditionalInfo(data.eventData);

            if (data.eventData && !this.areEventsEqual(this._prevEvent, data.eventData)) {
                this._prevEvent = data.eventData;
                this._tracker.recordEvent(data.eventData);
            }
        });
    }

    sendEvent(evData: ITrackingEvent) {
        try {
            evData = this.extendAdditionalInfo(evData);

            if (!this.areEventsEqual(this._prevEvent, evData)){
                this._prevEvent = evData;
                this._tracker.recordEvent(evData);
            }
        } catch(ex){
            console.error(ex);
        }
    }

    private extendAdditionalInfo(ev: ITrackingEvent) : ITrackingEvent {
        if (!ev) {
            return ev;
        }

        var _state = state.get();
        var errors = ErrorMapper.combine(_state.errors, _state.fields);
        
        const valid_cc = _state.fields.filter(f => f.type == FIELD_TYPES.NUMBER).every(x => x.isValid == true);
        const valid_cvv = _state.fields.filter(f => f.type == FIELD_TYPES.CVV).every(x => x.isValid == true);
        const valid_exp = _state.fields
            .filter(f => f.type == FIELD_TYPES.EXP_DATE || f.type == FIELD_TYPES.EXP_MONTH || f.type == FIELD_TYPES.EXP_YEAR)
            .every(x => x.isValid == true);

        let additionalInfo = <IAdditionalInfo>{
            rtp: errors.length == 0 && valid_cc && valid_cvv && valid_exp,
            valid_cc,
            valid_cvv,
            valid_exp,
            checkbox: _state.termsAccepted
        };

        ev.additionalInfo = Object.assign(additionalInfo, ev.additionalInfo);
        return ev;
    }

    private areEventsEqual(ev1: ITrackingEvent, ev2: ITrackingEvent) : boolean {
        if (!ev1 && !ev2) return true;
        if (!ev1 || !ev2) return false;

        if (ev1.name != ev2.name) return false;
        return JSON.stringify(ev1) == JSON.stringify(ev2);
    }
}

let splititTrackerDecorator = new SplititTrackerDecorator();
export {splititTrackerDecorator};