import { FLEX_FIELDS_ENV } from './constants';
import { PaymentButton } from './ui-components/payment-button';
import { SplititFlexFields } from './splitit-flex-fields';
import * as splititApi from 'splitit-sdk';
import { UI_MODE_HORIZONTAL } from './constants-ts';

// GET https://api.production.splitit.com/api/Infrastructure/SupportedCultures, manually refresh if ever added.
export const cultureDateFormatMap = {
    "da-DK": "DD-MM-YYYY",
    "de-DE": "DD.MM.YYYY",
    "en-AU": "D/MM/YYYY",
    "en-GB": "DD/MM/YYYY",
    "en-IE": "DD/MM/YYYY",
    "en-US": "M/D/YYYY",
    "es-ES": "DD/MM/YYYY",
    "fr-FR": "DD/MM/YYYY",
    "it-IT": "DD/MM/YYYY",
    "ja-JP": "YYYY/MM/DD",
    "nl-NL": "D-M-YYYY",
    "pt-PT": "DD/MM/YYYY",
    "ru-RU": "DD.MM.YYYY",
    "sv-SE": "YYYY-MM-DD",
    "tr-TR": "D.MM.YYYY",
    "zh-CN": "YYYY/M/D",
    "zh-HK": "D/M/YYYY"
};

class FieldConfigSection {
    public selector: string;
    public style: FieldStyleSection;

    overrideOptions(opt: any) {
        this.selector = opt.selector;
        this.style = new FieldStyleSection();
        this.style.overrideOptions(opt.style);
    }
}

export class FieldStyleSection {
    placeholderTextColor?: string;
    fontFamily?: string;
    fontSize?: string;
    textColor?: string;

    overrideOptions(opt: any) {
        opt = opt || {};

        if (opt.placeholderTextColor && opt.placeholderTextColor != ''){
            this.placeholderTextColor = opt.placeholderTextColor;
        }

        if (opt.fontFamily && opt.fontFamily != ''){
            this.fontFamily = opt.fontFamily;
        }

        if (opt.fontSize && opt.fontSize != ''){
            this.fontSize = opt.fontSize;
        }

        if (opt.textColor && opt.textColor != ''){
            this.textColor = opt.textColor;
        }
    }

    hasOverrides(){
        return (this.placeholderTextColor && this.placeholderTextColor != '') || 
            (this.fontFamily && this.fontFamily != '') || 
            (this.fontSize && this.fontSize != '') || 
            (this.textColor && this.textColor != '');
    }
}

class FlexFieldsConfigSection {
    public number: FieldConfigSection;
    public cvv: FieldConfigSection;
    public expirationDate: FieldConfigSection;
    public expirationMonth: FieldConfigSection;
    public expirationYear: FieldConfigSection;
    public cardholderName: FieldConfigSection;
    public style: FieldStyleSection;

    overrideOptions(opt: any) {
        let fields = ['number', 'cvv', 'expirationDate', 'expirationMonth', 'expirationYear', 'cardholderName'];
        fields.forEach(f => {
            if (opt[f]){
                this[f] = new FieldConfigSection();
                this[f].overrideOptions(opt[f]);
            }
        });

        this.style = new FieldStyleSection();
        this.style.overrideOptions(opt.style);
    }
}

class InstallmentPickerConfigSection{
    public selector: string;
    public ui?: string;
    public showPaymentSchedule: boolean = true;

    public overrideOptions(opt: any){
        this.selector = opt.selector;
        this.ui = opt.ui;

        if (opt.showPaymentSchedule !== null && opt.showPaymentSchedule !== undefined){
            this.showPaymentSchedule = opt.showPaymentSchedule;
        }
    }
}

class TermsConditionsConfigSection{
    public selector: string;

    public overrideOptions(opt: any){
        this.selector = opt.selector;
    }
}

class PaymentScheduleConfigSection{
    public selector: string;

    public overrideOptions(opt: any){
        this.selector = opt.selector;
    }
}

class ErrorBoxConfigSection{
    public selector: string;

    public overrideOptions(opt: any){
        this.selector = opt.selector;
    }
}

class PaymentButtonConfigSection{
    public selector: string;
    public onClick: (btn: PaymentButton, flexFieldsInstance: SplititFlexFields) => void;

    public overrideOptions(opt: any){
        this.selector = opt.selector;
        this.onClick = opt.onClick;
    }
}

class SplititFlexFieldsConfig {
    useSandboxApi: boolean;
    debug: boolean;
    currencySymbol: string;
    container?: string;
    culture: string;
    publicToken?: string;
    dateFormat: string;
    fields: FlexFieldsConfigSection;
    installmentPicker: InstallmentPickerConfigSection;
    termsConditions: TermsConditionsConfigSection;
    paymentSchedule: PaymentScheduleConfigSection;
    errorBox: ErrorBoxConfigSection;
    paymentButton: PaymentButtonConfigSection;
    consumerData?: splititApi.ConsumerData;
    billingAddress?: splititApi.AddressData;
    loader?: string | Function;
    
    private uiMode?: string;

    constructor(){
        this.fields = new FlexFieldsConfigSection();
        this.termsConditions = new TermsConditionsConfigSection();
        this.culture = 'en-US';
        this.dateFormat = cultureDateFormatMap['en-US'];
        this.debug = false;
        this.uiMode = undefined;
    }

    public getUiMode() : string {
        if (this.uiMode !== undefined){
            return this.uiMode;
        }

        if (!this.container){
            this.uiMode = null;
        } else {
            var rootContainer = document.querySelector(this.container);
            if (rootContainer && rootContainer.classList.contains('splitit-design-horizontal')){
                this.uiMode = UI_MODE_HORIZONTAL;
            } else {
                this.uiMode = null;
            }
        }

        return this.uiMode;
    }

    public overrideOptions(opt: any){
        this.consumerData = opt.consumerData;
        this.billingAddress = opt.billingAddress;
        this.publicToken = opt.publicToken;
        this.container = opt.container;
        this.loader = opt.loader;

        if (opt.fields){
            this.fields.overrideOptions(opt.fields);
        }

        if (opt.termsConditions){
            this.termsConditions.overrideOptions(opt.termsConditions);
        }

        if (opt.paymentSchedule){
            this.paymentSchedule = new PaymentScheduleConfigSection();
            this.paymentSchedule.overrideOptions(opt.paymentSchedule);
        }

        if (opt.errorBox){
            this.errorBox = new ErrorBoxConfigSection();
            this.errorBox.overrideOptions(opt.errorBox);
        }

        if (opt.paymentButton){
            this.paymentButton = new PaymentButtonConfigSection();
            this.paymentButton.overrideOptions(opt.paymentButton);
        }

        if (opt.installmentPicker){
            this.installmentPicker = new InstallmentPickerConfigSection();    
            this.installmentPicker.overrideOptions(opt.installmentPicker);
        }

        if (opt.useSandboxApi !== null && opt.useSandboxApi !== undefined) {
            this.useSandboxApi = opt.useSandboxApi;
        }

        if (opt.debug !== null && opt.debug !== undefined){
            this.debug = opt.debug;
        }

        if (opt.currencySymbol){
            this.currencySymbol = opt.currencySymbol;
        }

        if (opt.culture && cultureDateFormatMap[opt.culture]){
            this.culture = opt.culture;
            this.dateFormat = cultureDateFormatMap[this.culture];
        }

        if (opt.dateFormat){
            this.dateFormat = opt.dateFormat;
        }
    }

    public validate() {
        if (!this.fields.number) {
            console.error('Credit card number is obligatory flex field.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        } else if(!document.querySelector(this.fields.number.selector)){
            console.error('Credit card number selector did not return any valid element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (!this.fields.cvv) {
            console.error('CVV is obligatory flex field.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        } else if(!document.querySelector(this.fields.cvv.selector)){
            console.error('CVV selector did not return any valid element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (this.fields.expirationDate && (this.fields.expirationMonth || this.fields.expirationYear)) {
            console.error('Cannot use both expiration date and expiration month/year. Either use expiration date, or expirationMonth and expirationYear separately.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (this.fields.expirationMonth && !this.fields.expirationYear ||
            !this.fields.expirationMonth && this.fields.expirationYear) {
            console.error('Please provide both expirationMonth and expirationYear if not using expirationDate.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if(this.fields.expirationDate && !document.querySelector(this.fields.expirationDate.selector)){
            console.error('Expiration date selector did not return any valid element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        } else if(this.fields.expirationMonth && !document.querySelector(this.fields.expirationMonth.selector)){
            console.error('Expiration month selector did not return any valid element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        } else if(this.fields.expirationYear && !document.querySelector(this.fields.expirationYear.selector)){
            console.error('Expiration year selector did not return any valid element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (this.installmentPicker && this.installmentPicker.selector){
            if (document.querySelector(this.installmentPicker.selector) == null){
                console.error('Make sure installment picker selector is existing HTML element.');
                alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
                return false;
            }
        }

        if (!this.termsConditions.selector || document.querySelector(this.termsConditions.selector) == null){
            console.error('It is obligatory to specify valid element selector for terms & conditions link.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (this.errorBox && (!this.errorBox.selector || !document.querySelector(this.errorBox.selector))){
            console.error('Make sure error box selector is existing HTML element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        if (this.paymentButton && (!this.paymentButton.selector || !(document.querySelector(this.paymentButton.selector) as HTMLButtonElement))){
            console.error('Make sure error box selector is existing HTML element, and that it is a <button> element.');
            alert("Error initializing Splitit Flex Fields. Check developer console error for more details.");
            return false;
        }

        return true;
    }

    public isDebugMode(){
        return FLEX_FIELDS_ENV == 'sandbox' || FLEX_FIELDS_ENV == 'local' || this.debug;
    }
}

const config = new SplititFlexFieldsConfig();
config.useSandboxApi = FLEX_FIELDS_ENV !== 'production';
export { config };