import { FlexField } from "./field-base";
import { FIELD_TYPES } from "./field-types";
import Cleave from 'cleave.js';
import { localizer } from "../localization-provider";

export class ExpirationMonthField extends FlexField {
    public constructor(guid){
        super(guid);
       
        this._type = FIELD_TYPES.EXP_MONTH;
    }

    public updateChange(newValue: string){
        // Override default change, listen to cleave's
    }

    public updateKeypress(newValue: string){
        // Override default change, listen to cleave's
    }

    protected validate(){
        if (!this._value || this._value.length > 2 || this._value.length < 1 || parseInt(this._value) > 12 || parseInt(this._value) < 1){
            this._isValid = false;
            this._clientError = localizer.invalidExpirationDate;
        }

        // Cannot fully validate without knowing the validation year, so validation is done in field validation manager, in collector iframe.
    }

    public init(fieldSelector: string){
        super.init(fieldSelector);

        new Cleave(this._inputElement, {
            date: true,
            datePattern: ['m'],
            onValueChanged: (ev) => {
                if (this._value != ev.target.value){
                    this._value = ev.target.value;
                    if (this._value && this._value.length > 0){
                        this._everNonEmpty = true;
                    }
                    this._isValid = null;
                    this._clientError = null;
                    this.sendToCollector();
                }
            }
        });
    }
}