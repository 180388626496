import { getCollectorUrl, VERSION, FLEX_FIELDS_ENV } from './constants';
import * as splititApi from 'splitit-sdk';
import { apiFactory } from './api-factory';
import { state } from './state';
import { Collector } from './collector';
import { ApiErrorParser } from './api-error-parser';
import { utils } from './utils';
import { hide3dModal, IModal3dsIframeParams, IModal3dsIframeResult, render3dModalIframe } from './render-utils';
import { SplititFlexFields } from './splitit-flex-fields';
import { ErrorMapper } from './error-mapper';
import { planStore } from './services/plan-store';

export interface IFlexCheckoutConfig{
    publicToken: string;
    planNumber: string;
    skipCallingCreate?: boolean;
    numOfInstallments?: number;
};

export class SplititFlexCheckout {
    public version: string = VERSION;

    private _onSuccessCallback: (data: any) => void;
    private _onErrorCallback: (data: any) => void;
    private _on3ds1CompleteCallback: (data: IModal3dsIframeResult) => void;
    private _current3dModal: HTMLElement;
    private _isPlanDataLoaded: boolean;
    private _config: IFlexCheckoutConfig;
    private _onEventCallback?: (evType: string, oldValue: any, newValue: any) => void;
    
    constructor() {
    }

    init(config: IFlexCheckoutConfig){
        this._config = config;

        state.reset(false);
        planStore.init();
        this._isPlanDataLoaded = false;

        console.log('Flex Checkout Initialized');
        console.log(config);

        state.setIsCommiting(true);
        state.setIPN(config.planNumber);
        state.setPublicToken(config.publicToken);
        state.setIsSandbox(FLEX_FIELDS_ENV !== 'production');

        planStore.onLoaded((planData) => {
            this._isPlanDataLoaded = true;

            if (config.skipCallingCreate){
                this.perform3ds({
                    installmentPlan: {
                        installmentPlanNumber: config.planNumber
                    }
                });
            } else {
                this.performCreate(config);
            }
        });
        planStore.load();
    }

    private performCreate(config: IFlexCheckoutConfig){
        const requestData: splititApi.CreateInstallmentPlanRequest = {
            installmentPlanNumber: config.planNumber,
            planData: <splititApi.PlanData>{
                numberOfInstallments: config.numOfInstallments
            },
            planApprovalEvidence: <splititApi.PlanApprovalEvidence>{ areTermsAndConditionsApproved: true }
        };

        // TODO forter
        // if (this._forterToken && this._forterToken != ''){
        //     requestData.planData.extendedParams = {};
        //     requestData.planData.extendedParams[FORTER_COOKIE_NAME] = this._forterToken;
        // }

        let installmentPlanApi = apiFactory.getPlanApi();

        installmentPlanApi.installmentPlanCreate(<splititApi.InstallmentPlanCreateRequest>{ request: requestData })
            .then(result => {
                if (result && result.responseHeader && !result.responseHeader.succeeded) {
                    // This shouldn't happen since SDK throws error if responseHeader is not succeeeded, but just in case SDK changes at some point.
                    if (this._onErrorCallback){
                        this._onErrorCallback.call(this, this.mapErrors(result.responseHeader.errors));
                    }
                } else {
                    Collector.trimInstallmentPlanObject(result?.installmentPlan);
                }

                let successResponse = {data: result, is3d: false};
                SplititFlexFields.trimSuccessResponse(successResponse);
                this._onSuccessCallback.call(this, successResponse);
                state.setIsCommiting(false);
            }).catch(err => {
                let errorParser = new ApiErrorParser(err);
                let errors = errorParser.parse(false);

                if (errorParser.require3D){
                    this.perform3ds(err);
                } 
                else {
                    if (this._onErrorCallback){
                        this._onErrorCallback.call(this, this.mapErrors(errors));
                    }

                    state.setIsCommiting(false);
                    utils.logWarning(err);
                }
            });
    }

    destroy(){
        this._onSuccessCallback = null;
        this._onErrorCallback = null;
    }

    onSuccess(callback: (data: any) => void) {
        this._onSuccessCallback = callback;
        return this;
    }
    
    onError(callback: (data: any) => void) {
        this._onErrorCallback = callback;
        return this;
    }

    onEvent(callback: (evType: string, oldValue: any, newValue: any) => void){
        this._onEventCallback = callback;
    }

    private perform3ds(data: any) {
        try {
            this._current3dModal = render3dModalIframe(<IModal3dsIframeParams>{
                publicToken: state.get().publicToken,
                onComplete: (result) => this.process3dIframeResult(result, data),
                onUserClosed: () => {
                    if (this._onEventCallback){
                        this._onEventCallback('3dsmodal.closed', null, null);
                    }
                }
            });
        } catch(e){
            utils.logException(e);
        }
    }

    private process3dIframeResult(result3d: IModal3dsIframeResult, data: any){
        if (!this._onSuccessCallback){
            utils.logCustomError('3D flow not implemented correctly. When using 3D in iframe, having an onSuccess callback is required.');
            return;
        }

        if (this._on3ds1CompleteCallback){
            this._on3ds1CompleteCallback.call(this, result3d);
        }

        if (result3d.isSuccess){
            Collector.trimInstallmentPlanObject(data?.installmentPlan);
            
            if (data?.installmentPlan){
                data.responseHeader = {succeeded: true, errors: [], traceId: `${data.responseHeader?.traceId}-updated`};
            }

            var successRes = {
                secure3dRedirectUrl: result3d?.redirectUrl,
                successRedirectUrl: result3d?.redirectUrl,
                is3d: true,
                data: data
            };

            SplititFlexFields.trimSuccessResponse(successRes);

            if (this._config.skipCallingCreate){
                if (this._isPlanDataLoaded){
                    successRes.data.installmentPlan.refOrderNumber = state.get().refOrderNumber;
                    this._onSuccessCallback.call(this, successRes);
                } else {
                    utils.logCustomError("FlexCheckout.process3dIframeResult: Plan not yet loaded. Weird.", { obj: successRes });
                    planStore.onLoaded((planData) => {
                        utils.logCustomError("FlexCheckout.process3dIframeResult: Recovered.", { obj: successRes });
                        successRes.data.installmentPlan.refOrderNumber = planData.refOrderNumber;
                        this._onSuccessCallback.call(this, successRes);
                    });
                }
            } else {
                this._onSuccessCallback.call(this, successRes);
            }
        } else {
            if (this._onErrorCallback){
                this._onErrorCallback.call(this, this.mapErrors([{ 
                    message: "3D secure verification was not completed successfully.", 
                    errorCode: "641-F", 
                    additionalInfo: result3d?.redirectUrl 
                }]));
            }
        }

        hide3dModal(this._current3dModal);
    }

    private mapErrors(errors: Array<any>){
        return ErrorMapper.combine(errors, []);
    }
};