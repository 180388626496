import { ModelError } from "splitit-sdk";
import { FieldDescriptorInfo } from "./ui-components/field-descriptor";
import { FIELD_TYPES } from "./fields/field-types";

export interface IErrorDescriptor {
    key: string;
    error: string;
    code?: string;
    showError: boolean;
    fieldTypes?: Array<string>;
    fieldType?: string; // Backward compatibility, only show first field type.
}

export const ErrorCodes = {
    TERMS_NOT_SELECTED: '01T'
};

const errorMap = {
    "504": [FIELD_TYPES.NUMBER],
    "521": [FIELD_TYPES.NUMBER],
    "542": [FIELD_TYPES.NUMBER],
    "564": [FIELD_TYPES.NUMBER],
    "568": [FIELD_TYPES.NUMBER],
    "541": [FIELD_TYPES.NUMBER],
    "5041": [FIELD_TYPES.NUMBER],
    "5042": [FIELD_TYPES.NUMBER],
    "5421": [FIELD_TYPES.NUMBER],
    "5422": [FIELD_TYPES.NUMBER],
    "5801": [FIELD_TYPES.NUMBER],
    "5802": [FIELD_TYPES.NUMBER],

    "522": [FIELD_TYPES.EXP_DATE, FIELD_TYPES.EXP_MONTH, FIELD_TYPES.EXP_YEAR],
    "540": [FIELD_TYPES.EXP_DATE, FIELD_TYPES.EXP_MONTH, FIELD_TYPES.EXP_YEAR],
    "569": [FIELD_TYPES.EXP_DATE, FIELD_TYPES.EXP_MONTH, FIELD_TYPES.EXP_YEAR],
    "570": [FIELD_TYPES.EXP_DATE, FIELD_TYPES.EXP_MONTH, FIELD_TYPES.EXP_YEAR],
    "581": [FIELD_TYPES.EXP_DATE, FIELD_TYPES.EXP_MONTH, FIELD_TYPES.EXP_YEAR],

    "520": [FIELD_TYPES.CVV]
}

export class ErrorMapper {
    public static combine(errors: Array<ModelError>, fields: Array<FieldDescriptorInfo>) : Array<IErrorDescriptor> {
        let result = new Array<IErrorDescriptor>();

        errors.forEach(e => {
            let errorMapped = <IErrorDescriptor>{
                key: e.errorCode,
                error: e.message,
                code: e.errorCode,
                fieldTypes: null,
                fieldType: null,
                showError: true
            };

            if (e.errorCode && errorMap[e.errorCode]){
                errorMapped.fieldTypes = errorMap[e.errorCode];
                errorMapped.fieldType = errorMapped.fieldTypes[0];
            }

            result.push(errorMapped);
        });

        fields.forEach(f => {
            if (f.errors && f.errors.length > 0){
                f.errors.forEach(fe => {
                    result.push(<IErrorDescriptor>{
                        key: fe,
                        error: fe,
                        fieldTypes: [f.type],
                        fieldType: f.type,
                        showError: f.showValidationError
                    });
                });
                
            }
        });

        return result;
    }
    
}
