﻿export var FLEX_FIELDS_URL = __URL__;
export var FLEX_FIELDS_ENV = __ENV__;
export var DATA_TRACKING_ENDPOINT = __DATA_TRACKING_ENDPOINT__;
export var GA_UA_ID = __GA_UA_ID__;
export var VERSION = __VERSION__;
export var FLEX_FIELDS_HELP_URL = FLEX_FIELDS_URL + "index.html";

export function getCollectorUrl() {
    return FLEX_FIELDS_URL + "formfieldcollector.html";
}
