import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { config } from "../../config";
import { utils } from "../../utils";
import { state } from "../../state";
import { frameManager, MessageTypes } from "../../frame-manager";
import { TrackerEventPayload } from "../../frame-manager-payloads";

export class InstallmentPickerDropdownRenderer extends InstallmentPickerRenderer {
    private _selectOptions: { numPayments: string; amountPerMonth: string; }[];
    private _selectorTextRight: HTMLElement;
    private _selectorTextLeft: HTMLElement;

    protected setSelectedValue(newValue){
        super.setSelectedValue(newValue);

        this._selectorTextLeft.innerText = localizer.numPayments(newValue);
        this._selectorTextRight.innerText = this.getPaymentCalculationText(newValue);
    }

    public render(container: HTMLElement) {
        container.classList.add("splitit-installment-picker-dropdown");

        this._selectOptions = this.options.map(o => {
            return {
                numPayments: localizer.numPayments(o),
                amountPerMonth: this.getPaymentCalculationText(o)
            };
        });

        let template = require('./dropdown.desktop.t.html');
        container.innerHTML = template({ options: this._selectOptions });

        let chooserElement = container.querySelector('.splitit-ip-d-desktop-chooser') as HTMLElement;
        window.addEventListener("mouseup", (event) => {
            chooserElement.style.display = "none";
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.pickerDropdownClose());
        });
        
        let selectorButton = container.querySelector('.splitit-ip-d-desktop-sel') as HTMLElement;
        selectorButton.onmouseup = (ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            chooserElement.style.display = "block";

            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.pickerDropdownOpen());
        };
        this._selectorTextLeft = container.querySelector('.splitit-ip-d-desktop-sel-text-left') as HTMLElement;
        this._selectorTextRight = container.querySelector('.splitit-ip-d-desktop-sel-text-right') as HTMLElement;

        chooserElement.querySelectorAll('.splitit-ip-d-desktop-chooser-item').forEach((el: HTMLElement, idx) => {
            el.onclick = (ev) => {
                this.setSelectedValue(this.options[idx]);
            };
        });

        this.setSelectedValue(this.initialSelection ?? this.options[Math.trunc(this.options.length / 2)]);
    }

    protected getPickerType() : string{
        return "dropdown-desktop";
    }
}