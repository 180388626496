import { config, cultureDateFormatMap } from "./config";
import moment = require("moment-mini");
import * as splititApi from 'splitit-sdk';
import { FIELD_TYPES } from "./fields/field-types";
import {FLEX_FIELDS_ENV, VERSION} from './constants';
import { frameManager, MessageTypes } from "./frame-manager";
import { TrackerEventPayload } from "./frame-manager-payloads";

import { BrowserClient, Exception, Severity } from "@sentry/browser";

var urlParams = require('url-params');

class Utils{
    private _interactionElements: Array<HTMLElement>;
    private _sentryClient: BrowserClient;
    private _hadFirstInteraction: boolean;
    public pickerErrorCodes: Array<string> = ['5041', '5801', '5421'];

    constructor(){
        this._interactionElements = new Array<HTMLElement>();
        this._hadFirstInteraction = false;

        var $this = this;
        this._sentryClient = new BrowserClient({
            dsn: 'https://2513b306226e44a69a990f852c462c8b@o292137.ingest.sentry.io/5293536',
            release: `flex-fields-${VERSION}`,
            environment: FLEX_FIELDS_ENV,
            beforeSend: function(ev){
                if (!ev.extra){
                    ev.extra = {};
                }

                ev.extra['merchant-url'] = $this.hostUrl();
                return ev;
            }
          });
    }

    public trace(msg: string) {
        this._sentryClient.captureMessage(msg, Severity.Info);
    }

    public logWarning(msg: string) {
        if (config.isDebugMode()){
            console.warn('[splitit] ' + msg);
        }

        this._sentryClient.captureMessage(msg, Severity.Warning);
    }

    public logException(ex: any, outputToConsole: boolean = true) {
        outputToConsole && console.error(ex);
        this._sentryClient.captureException(ex);
    }

    public logError(err: Error) {
        this._sentryClient.captureException(err);
    }

    public logCustomError(msg: string, data?: any) {
        console.error(msg);
        if (data){
            console.error(data);
        }

        this._sentryClient.captureException({
            error: msg,
            data: data
        });
    }

    formatString(str: string, args: any): string {
        for (let key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }

        return str;
    }

    public recordFirstInteraction(el: HTMLElement){
        if (!this._hadFirstInteraction){
            this._interactionElements.push(el);
            el.addEventListener('mousedown', this.mouseEvent);
            el.addEventListener('touchstart', this.mouseEvent);
            el.addEventListener('mouseenter', this.mouseEvent);
        }
    }

    private mouseEvent = () => {
        this._hadFirstInteraction = true;
        frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.userInteraction());

        this._interactionElements.forEach(el => {
            try {
                el.removeEventListener('mousedown', this.mouseEvent);
                el.removeEventListener('touchstart', this.mouseEvent);
                el.removeEventListener('mouseenter', this.mouseEvent);
            } catch (e){
                console.warn(e);
            }
        })
    }

    formatDate(date: string | Date): string {
        return moment(date).format(config.dateFormat);
    }

    public verifySupportedCulture(opts: any) {
        if (opts.culture && cultureDateFormatMap && !cultureDateFormatMap[opts.culture]){
            this.logCustomError("Unsupported FF culture.", { requestedCulture: opts.culture });
        }
    }

    public formatCurrency(amount: number, currencySymbol: string, currencyDecimalPlaces: number){
        return `${currencySymbol}${utils.formatDecimalToLocale(amount, currencyDecimalPlaces)}`;
    }

    public formatDecimalToLocale(amount: number, decimalPlaces: number){
        if (amount % 1 == 0){
            decimalPlaces = 0;
        }
        
        return `${amount.toLocaleString(config.culture, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}`;
    }

    public guid(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public appendUrlParams(url: string, queryString: any) : string {
        for(var key in queryString){
            if (queryString.hasOwnProperty(key)){
                url = urlParams.set(url, key, queryString[key]);
            }
        }
        return url;
    }

    public areArraysEqual(arr1: Array<any>, arr2: Array<any>){
        if (arr1.length != arr2.length){
            return false;
        }

        let result = true;

        arr1.forEach((el, idx) => {
            if (el != arr2[idx]){
                result = false;
            }
        });

        return result;
    }

    public parseCardData(data: any) : splititApi.CardData {
        let expMonth = data[FIELD_TYPES.EXP_MONTH];
        let expYear = data[FIELD_TYPES.EXP_YEAR];

        if (!expMonth){
            let expDate = data[FIELD_TYPES.EXP_DATE];
            if (expDate && expDate.indexOf('/') > 0){
                expMonth = data[FIELD_TYPES.EXP_DATE].split('/')[0];
                expYear = data[FIELD_TYPES.EXP_DATE].split('/')[1];
            }
        }

        return <splititApi.CardData>{
            cardNumber: data[FIELD_TYPES.NUMBER],
            cardHolderFullName: data[FIELD_TYPES.CARDHOLDER_NAME],
            cardExpMonth: expMonth,
            cardExpYear: expYear,
            cardCvv: data[FIELD_TYPES.CVV]
        };
    }

    public isShitholeBrowser(){
        var ua = window.navigator.userAgent;
        return /MSIE|Trident/.test(ua);
    }

    public getCookie(name: string) {
        const cookieKey = `${name}=`;
        const baseCookies = decodeURIComponent(document.cookie);
        const cookieArray = baseCookies.split(';');
      
        for (let i = 0; i < cookieArray.length; i += 1) {
          let cookie = cookieArray[i];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(cookieKey) > -1) {
            return cookie.substring(cookieKey.length, cookie.length);
          }
        }
        return "-1";
    }

    private hostUrl(){
        var isInIframe = (parent !== window);

        if (isInIframe) {
            return document.referrer;
        }

        return document.location.hostname;
    }
}

const utils = new Utils();
export {utils};