import { config } from "../config";
import { state, ISplititState, IStateActiveErrors } from "../state";
import { utils } from "../utils";

export class ErrorBox {
    _container: HTMLElement;
    
    constructor(){
        if (config.errorBox && config.errorBox.selector){
            this._container = document.querySelector(config.errorBox.selector) as HTMLElement;
            this._container.classList.add("splitit-error-box");
            this._container.style.display = "none";

            state.onChange(old => {
                let errorState = state.getActiveErrors(old);
                if (errorState.isChanged && errorState.hasGeneralErrors){
                    this._container.style.display = "block";
                    this.render(errorState);
                } else if(!errorState.hasGeneralErrors) {
                    this._container.style.display = "none";
                }
            });
        }
    }    

    public destroy() {
        if (this._container){
            this._container.innerHTML = "";
        }
    }

    render(currentErrorState: IStateActiveErrors){
        if (!this._container){
            return;
        }

        this._container.innerHTML = '';

        let ul = document.createElement('ul');
        this._container.appendChild(ul);

        currentErrorState.errors.filter(e => e.fieldTypes == null || e.fieldTypes.length == 0).forEach(e => {
            let li = document.createElement('li');
            li.innerText = e.error;
            ul.appendChild(li);
        });
    }
}