import { localizer } from "../localization-provider";
import { FlexField } from "./field-base";
import { FIELD_TYPES } from "./field-types";

export class CardholderNameField extends FlexField {
    public constructor(guid: string, uiMode){
        super(guid, uiMode);
       
        this._type = FIELD_TYPES.CARDHOLDER_NAME;
    }

    protected validate(){
        if (!this._value || this._value == ''){
            this._isValid = false;
            this._clientError = localizer.invalidCardholderName;
        }
    }
}