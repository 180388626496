import { FLEX_FIELDS_ENV } from './constants';
import { FIELD_TYPES } from './fields/field-types';
import { frameManager, MessageTypes, FrameManagerRoles } from './frame-manager';
import * as splititApi from 'splitit-sdk';
import { CollectCardDataPayload, TrackerEventPayload } from './frame-manager-payloads';
import { state } from './state';
import { FieldValidationManager } from './fields/field-validation-manager';
import { ApiErrorParser } from './api-error-parser';
import { ModelError } from 'splitit-sdk';
import { utils } from './utils';
import { apiFactory } from './api-factory';
import { FORTER_COOKIE_NAME, NO_TRIM_MERCHANT_IDS } from './constants-ts';
import { env, ProcessorName, ThreeDsTwoTokenizer, ThreeDsTwoTokenizerConfig, EventFactory, ISplititError } from 'splitit-utils';
import { splititTrackerDecorator } from './tracking/splitit-tracker-decorator';

export class Collector {
    private _collectedValues: any;
    private _forterToken: any;
    private _fieldValidationManagers: any;
    private _commitDictionary: any;
    private _3ds2Tokenizer: ThreeDsTwoTokenizer;

    constructor(guid) {
        frameManager.init(FrameManagerRoles.COLLECTOR, guid);
        state.reset();
        
        splititTrackerDecorator.init();

        frameManager.notify(MessageTypes.COLLECTOR_INIT);

        this._collectedValues = {};
        this._forterToken = null;
        this._commitDictionary = {};
        this._fieldValidationManagers = {};

        state.onChange(old => {
            if (!this._3ds2Tokenizer && state.get().is3ds2Supported){
                this._3ds2Tokenizer = new ThreeDsTwoTokenizer({
                    Env: <env>(FLEX_FIELDS_ENV == 'local' ? 'sandbox' : FLEX_FIELDS_ENV),
                    PublicToken: state.get().publicToken,
                    ProcessorName: <ProcessorName>(state.get().processorName),
                  });
            }
        })

        frameManager.subscribe(MessageTypes.COLLECT_CARD_DATA, this, (data: CollectCardDataPayload) => {
            if (data.commitId){
                if (!this._commitDictionary[data.commitId]){
                    this._commitDictionary[data.commitId] = [];
                }

                this._commitDictionary[data.commitId].push(data);
            }

            this._collectedValues[data.fieldType] = data.value;

            let field = state.get().fields.filter(f => f.type == data.fieldType)[0];
            if (!field){
                return;
            }

            if (data.fieldType == FIELD_TYPES.NUMBER && data.forter){
                this._forterToken = data.forter;
            }
            
            let validationManager = new FieldValidationManager(field, data, Object.assign({}, this._collectedValues), this._3ds2Tokenizer);
            this._fieldValidationManagers[data.fieldType] = validationManager;

            validationManager.validate((validationResult, serverErrors: Array<ModelError>) => {
                if (this._fieldValidationManagers[data.fieldType] === validationManager){
                    // Nothing changed in the meantime, ok to update state. If anything was changed in the meantime,
                    // it will get picked up in next event loop. This can happen if sever side validation is triggered, but in the meantime
                    // payment button is clicked for instance.
                    state.updateField(validationResult);
                    if (serverErrors !== null){
                        state.setErrors(serverErrors);
                    }

                    var fieldErrors = new Array<ISplititError>();
                    if (serverErrors){
                        fieldErrors = serverErrors.map(s => <ISplititError>{ code: s.errorCode, message: s.message });
                    }
                    if (validationResult.errors){
                        fieldErrors.push(...validationResult.errors.map(s => <ISplititError>{ message: s }));
                    }

                    if (fieldErrors.length == 0 || validationResult.showValidationError){
                        splititTrackerDecorator.sendEvent(EventFactory.InputValidated(data.fieldType, fieldErrors.length == 0, fieldErrors, TrackerEventPayload.getUiStateInfo()));
                    }

                    if (data.commitId && this._commitDictionary[data.commitId].length == state.get().fields.length){
                        // Everything is collected, process it...
                        this.processPayment();
                        delete this._commitDictionary[data.commitId];
                        this._forterToken = null;
                    }
                } else {
                    // console.log(`Skipping field update for ${data.fieldType}`);
                }
            });
        });
    }

    private processPayment(){
        try{
            let allValid = state.get().fields.every(f => f.isValid !== false);
            if (allValid){
                this.commitData();
            } else {
                let fieldsToTriggerValidation = state.get().fields.filter(f => f.isValid === false);
                fieldsToTriggerValidation.forEach(f => {
                    f.showValidationError = true;
                    state.updateField(f);
                });
            }
        } catch (e){
            utils.logException(e);
        }
    }

    private validateRequiredPlanData() {
        if (!state.get().ipn) {
            utils.logCustomError("Splitit plugin not configured correctly: installment plan number is never set.");
            return false;
        }

        if (!state.get().publicToken) {
            utils.logCustomError("Splitit plugin not configured correctly: public token is never set.");
            return false;
        }

        return true;
    }

    private commitData(maxAttempts: number = 3) {
        if (!this.validateRequiredPlanData() || state.get().isCommiting) {
            return;
        }

        state.setIsCommiting(true);
        state.setErrors([]);

        let cardData = utils.parseCardData(this._collectedValues);

        const requestData: splititApi.CreateInstallmentPlanRequest = {
            creditCardDetails: cardData,
            installmentPlanNumber: state.get().ipn,
            planData: <splititApi.PlanData>{
                numberOfInstallments: state.get().numInstallments
            }
        };

        if (this._forterToken && this._forterToken != ''){
            requestData.planData.extendedParams = {};
            requestData.planData.extendedParams[FORTER_COOKIE_NAME] = this._forterToken;
        }

        let tcApproved = state.get().termsAccepted;
        if (tcApproved === true || tcApproved === false) {
            requestData.planApprovalEvidence = <splititApi.PlanApprovalEvidence>{ areTermsAndConditionsApproved: tcApproved };
        }

        let installmentPlanApi = apiFactory.getPlanApi();

        installmentPlanApi.installmentPlanCreate(<splititApi.InstallmentPlanCreateRequest>{ request: requestData })
            .then(result => {
                if (result && result.responseHeader && !result.responseHeader.succeeded) {
                    // This shouldn't happen since SDK throws error if responseHeader is not succeeeded, but just in case SDK changes at some point.
                    state.setErrors(result.responseHeader.errors);
                } else {
                    if (result?.installmentPlan?.strategy) {
                        state.setStrategy(result.installmentPlan.strategy);
                    }

                    Collector.trimInstallmentPlanObject(result?.installmentPlan);
                    frameManager.notify(MessageTypes.COLLECTOR_COMMIT_COMPLETE, { data: result });
                }

                state.setIsCommiting(false);
            }).catch(err => {
                let errorParser = new ApiErrorParser(err);
                let errors = errorParser.parse(true);

                if (errorParser.require3D){
                    this.perform3ds(err);
                } else if(errorParser.possibleConcurrencyError && maxAttempts > 0){
                    state.setIsCommiting(false);
                    this.commitData(maxAttempts - 1);
                } 
                else {
                    if (errorParser.possibleConcurrencyError){
                        state.setErrors(errors);
                    }
                    state.setIsCommiting(false);
                    utils.logWarning(err);
                }
            });
    }

    private perform3ds(data) {
        Collector.trimInstallmentPlanObject(data?.installmentPlan);

        frameManager.notify(MessageTypes.COLLECTOR_COMMIT_COMPLETE, Object.assign({ is3ds1: true }, data));
        state.setIsCommiting(false);
    }

    public static trimInstallmentPlanObject(installmentPlan: splititApi.InstallmentPlan){
        let keysToKeep = ['installmentPlanNumber', 'refOrderNumber'];

        if (installmentPlan && !NO_TRIM_MERCHANT_IDS.includes(installmentPlan.merchant?.id)){
            Object.keys(installmentPlan).forEach(k => {
                if (keysToKeep.indexOf(k) == -1){
                    delete installmentPlan[k];
                }
            });
        }
    }
}