﻿import { SplititFlexFields } from './splitit-flex-fields';
import { SplititFlexCheckout } from './splitit-flex-checkout';
import { Collector } from './collector';
import { FlexFieldFactory } from './fields/factory';
import { FLEX_FIELDS_URL, FLEX_FIELDS_ENV } from './constants';
import { config } from './config';
import { utils } from './utils';

export function setup(options) {
    utils.verifySupportedCulture(options);
    config.overrideOptions(options);
    
    if (window['splitit-ff-instance']){
        window['splitit-ff-instance'].destroy();
    }

    window['splitit-ff-instance'] = new SplititFlexFields();
    return window['splitit-ff-instance'];
}

export function completeCheckout(options) {
    utils.verifySupportedCulture(options);
    
    if (window['splitit-ff-checkout-instance']){
        window['splitit-ff-checkout-instance'].destroy();
    }

    window['splitit-ff-checkout-instance'] = new SplititFlexCheckout();
    window['splitit-ff-checkout-instance'].init(options);
    return window['splitit-ff-checkout-instance'];
}

export function field(type, guid, uiMode) {
    return FlexFieldFactory.create(type, guid, uiMode);
}

export function collector(guid) {
    return new Collector(guid);
}

export function uuidv4() {
    return utils.guid();
}