import { IUpdateDetailsData } from "../models/update-details-model";
import { state } from "../state";
import * as splititApi from 'splitit-sdk';
import { apiFactory } from "../api-factory";
import { ApiErrorParser } from "../api-error-parser";
import { utils } from "../utils";

class ShopperDetailsStore {
    private _data?: IUpdateDetailsData;
    private _callback?: () => void;

    public init(){
        this._data = null;
        
        state.onChange(old => {
            if (old.ipn != state.get().ipn){
                this.save();
            }
        });
    }

    public update(data: IUpdateDetailsData, callback?: () => void){
        if (!this._data){
            this._data = {};
        }
        Object.assign(this._data, data);

        this._callback = callback;

        if (state.get().ipn){
            this.save();
        }
    }

    private save(){
        if (this._data && state.get().ipn){
            const requestData: splititApi.InitiateInstallmentPlanRequest = {
                installmentPlanNumber: state.get().ipn
            };

            if (this._data.consumerData){
                requestData.consumerData = this._data.consumerData;
            }

            if (this._data.billingAddress){
                requestData.billingAddress = this._data.billingAddress;
            }

            if (this._data.refOrderNumber){
                requestData.planData = {
                    refOrderNumber: this._data.refOrderNumber
                }
            }
    
            let installmentPlanApi = apiFactory.getPlanApi();
            
            installmentPlanApi.installmentPlanInitiate(<splititApi.InstallmentPlanInitiateRequest>{ request: requestData })
                .then(serverResult => {
                    this._data = null;
                    if (serverResult.installmentPlan && serverResult.installmentPlan.strategy){
                        state.setStrategy(serverResult.installmentPlan.strategy);
                    }

                    if (serverResult && serverResult.responseHeader && !serverResult.responseHeader.succeeded) {
                        // This shouldn't happen since SDK throws error if responseHeader is not succeeeded, but just in case SDK changes at some point.
                        let errorParser = new ApiErrorParser(serverResult.responseHeader.errors);
                        errorParser.parse(true);
                        console.error("[splitit] Possible misconfiguration - cannot add billing address or consumer data.");
                        console.error(serverResult);
                    } else if (this._callback) {
                        this._callback();
                    }
                }).catch(err => {
                    let errorParser = new ApiErrorParser(err);
                    errorParser.parse(true);
                    utils.logException(err, false);
                });
        }
    }
}

const shopperDetailsStore = new ShopperDetailsStore();
export {shopperDetailsStore};