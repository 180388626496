import { config } from "../config";
import { utils } from "../utils";
import { localizer } from "../localization-provider";
import { state } from "../state";
import { frameManager, MessageTypes } from "../frame-manager";
import { TrackerEventPayload } from "../frame-manager-payloads";
import { IFramePopup, GenericPopupBuilder, POPUP_EVENTS } from "splitit-utils";
import {VERSION} from '../constants.js';

export class TermsConditionsLink {
    private _container: HTMLElement;
    private _termsLink: HTMLAnchorElement;
    private _privacyLink: HTMLAnchorElement;
    private _checkbox: HTMLInputElement;

    constructor(){
        this._container = document.querySelector(config.termsConditions.selector) as HTMLElement;
        this._container.classList.add('splitit-terms-conditions');
        utils.recordFirstInteraction(this._container);
        
        this._termsLink = null;
        this._privacyLink = null;
    }

    public destroy(){
        this._container.innerHTML = "";
    }

    public render() {
        let id = "l-" + utils.guid();

        this._checkbox = document.createElement('input');
        this._checkbox.type = 'checkbox';
        this._checkbox.id = id;
        this._checkbox.disabled = true;
        this._checkbox.onchange = () => {
            state.setTermsAccepted(this._checkbox.checked);
            setTimeout(() => frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.termsCheckboxChanged(this._checkbox.checked)), 25);
        };
        this._container.appendChild(this._checkbox);

        let lbl = document.createElement('label');
        lbl.htmlFor = id;
        localizer.register(() => {
            lbl.innerHTML = localizer.termsConditionsHtml;
            this.initializeLinks(lbl);
            this.updateFromState();
        });
        this._container.appendChild(lbl);

        this.initializeLinks(lbl);

        // Automatically uncheck T&C if num installments changed - removed per request by Yan on Feb 25.
        state.onChange((old) => {
            if (old.numInstallments != state.get().numInstallments || 
                !old.termsConditionsUrl && state.get().termsConditionsUrl ||
                !old.privacyPolicyUrl && state.get().privacyPolicyUrl){
                this.updateFromState();
            }

            if (state.get().demoMode && old.demoMode != state.get().demoMode){
                this.updateFromState();
            }
        });

        this.updateFromState();
    }

    private openPopup(type: 'tnc' | 'pp', url?: string, numInstallments?: number){
        if (!url){
            return;
        }

        var builder = new GenericPopupBuilder({
            url: url,
            urlParams: {
                numInstallments, popup: true
            },
            version: VERSION
        });

        var popup = builder.build();
        popup.addEventListener(callbackEv => {
            if (callbackEv == POPUP_EVENTS.CLOSED){
                if (type == 'tnc'){
                    frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.termsPopupClose());
                } else {
                    frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.privacyPopupClose());
                }
            }
        });
        popup.show();
    }

    private initializeLinks(lbl: HTMLLabelElement) {
        this._termsLink = lbl.querySelector('a.splitit-tc-t') as HTMLAnchorElement;
        this._termsLink.onclick = (ev) => {
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.termsLinkClick());
            this.openPopup('tnc', state.get().termsConditionsUrl, state.get().numInstallments);
            // Propagate only if not accepted
            return !state.get().termsAccepted;
        };

        this._privacyLink = lbl.querySelector('a.splitit-tc-p') as HTMLAnchorElement;
        this._privacyLink.onclick = (ev) => {
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.privacyLinkClick());
            this.openPopup('pp', state.get().privacyPolicyUrl, state.get().numInstallments);
            // Propagate only if not accepted
            return !state.get().termsAccepted;
        };
    }

    private updateFromState(){
        if(state.get().demoMode){
            this._checkbox.disabled = false;
        } else if (!state.get().termsConditionsUrl || !state.get().privacyPolicyUrl){
            this._checkbox.disabled = true;
            this._checkbox.checked = false;
        } else {
            this._checkbox.disabled = false;
        }
    }
}