import { config } from "../config";
import { state } from "../state";
import { localizer } from "../localization-provider";
import { frameManager, MessageTypes } from "../frame-manager";
import { TrackerEventPayload } from "../frame-manager-payloads";
import { utils } from "../utils";

export class PaymentButton {
    _container: HTMLButtonElement;
    _isLoading: boolean;
    _isDisabled: boolean;
    _callback: () => void;

    constructor(){
        if (config.paymentButton && config.paymentButton.selector){
            this._container = document.querySelector(config.paymentButton.selector) as HTMLButtonElement;
            this._container.classList.add("splitit-payment-button");
            this._container.onclick = () => {
                if (this._callback && !this._isDisabled && !this._isLoading){
                    this._callback.call(this);
                }
            };
            utils.recordFirstInteraction(this._container);

            this._isDisabled = true;
            this._isLoading = false;

            state.onChange(old => {
                const newState = state.get();
                if (newState.isCommiting != old.isCommiting || newState.termsAccepted != old.termsAccepted){
                    this._isDisabled = !newState.termsAccepted || newState.isCommiting;
                    this._isLoading = newState.isCommiting;
                    
                    this.render();
                }
            });

            localizer.register(() => this.render());
        }
    }

    public destroy() {
        if (this._container){
            this._container.innerHTML = "";
        }
    }

    public onClick(callback: () => void){
        this._callback = callback;
    }

    public setIsLoading(isLoading: boolean) : void {
        if (this._isLoading != isLoading){
            this._isLoading = isLoading;
            if (!this._isDisabled && isLoading){
                this._isDisabled = true;
            }
            this.render();
        }
    }

    public disable() : void {
        if (!this._isDisabled){
            this._isDisabled = true;
            this.render();
        }
    }

    public enable() : void {
        if (!this._isDisabled && !this._isLoading){
            this._isDisabled = false;
            this.render();
        }
    }

    public render(){
        if (!this._container){
            return;
        }
        
        this._container.disabled = this._isDisabled;

        if (this._isLoading){
            let template = require('./payment-button-content.t.html');
            this._container.innerHTML = template({
                text: localizer.pay
            });
        } else {
            this._container.innerHTML = localizer.pay
        }
    } 
}