import { FLEX_FIELDS_ENV } from '../constants';
import { state } from "../state";
import { frameManager, MessageTypes } from '../frame-manager';
import { TrackerEventPayload } from '../frame-manager-payloads';
import { localizer } from '../localization-provider';
import { LearnMorePopupBuilder, POPUP_EVENTS } from 'splitit-utils';

export class HowSplititWorksLink {
    private _link: HTMLAnchorElement;

    constructor(additionalClass?: string) {
        this._link = document.createElement('a');

        if (additionalClass){
            this._link.classList.add(additionalClass);
        }
    }

    public hide(){
        this._link.remove();
    }

    public destroy(){
        this._link.remove();
    }

    public addTo(container: HTMLElement, position?: number){
        this._link.classList.add('splitit-ps-h-link');
        this._link.classList.add('splitit-ps-h-link-how-works');
        this._link.classList.add('float-right');
        this._link.attributes['href'] = 'javascript:void(0);';
        this._link.text = localizer.howSplititWorks;
        localizer.register(() => this._link.text = localizer.howSplititWorks);

        if (position !== null && position !== undefined){
            container.insertBefore(this._link, container.children[position]);
        } else {
            container.appendChild(this._link);
        }
        
        this._link.onclick = (ev) => {
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.howSplititWorksOpen());

            var currentState = state.get();

            var builder = new LearnMorePopupBuilder({
                env: FLEX_FIELDS_ENV == 'local' ? 'sandbox' : FLEX_FIELDS_ENV,
                publicToken: currentState.publicToken,
                numInstallments: currentState.numInstallments,
                culture: currentState.culture
            });
            
            var popup = builder.build();
            popup.addEventListener((popupEvent) => {
                if (popupEvent == POPUP_EVENTS.CLOSED){
                    frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.howSplititWorksClosed());
                }
            });
            popup.show();
        };
    }
}