import {Localization, localizer} from "../localization-provider";
import { PaymentScheduleItem, getPaymentSchedule } from "./calculator";
import { config } from "../config";
import {FLEX_FIELDS_ENV} from '../constants';
import { utils } from "../utils";
import * as splititApi from 'splitit-sdk';
import { state } from "../state";
import { frameManager, MessageTypes } from "../frame-manager";
import { TrackerEventPayload } from "../frame-manager-payloads";
import {LearnMorePopupBuilder, POPUP_EVENTS} from 'splitit-utils';
import { planStore } from "../services/plan-store";
import { HowSplititWorksLink } from "../ui-components/how-splitit-works-link";

interface InstallmentUiModel{
    paymentOrd: string;
    date: string;
    amount: string;
    requiredCredit: string;
}

interface PaymentScheduleUiModel{
    localizer: Localization;
    installments: Array<InstallmentUiModel>;
}

export class PaymentScheduleRenderer {
    private _expanded: boolean;
    public container: HTMLElement;

    private _schedule: splititApi.GetInstallmentsScheduleResponse;

    constructor(){
        state.onChange((old) => {
            if (old.numInstallments != state.get().numInstallments || 
                old.amount != state.get().amount || 
                old.isSecure != state.get().isSecure ||
                old.firstChargeDate != state.get().firstChargeDate ||
                old.firstInstallmentAmount != state.get().firstInstallmentAmount){

                if (config.isDebugMode() && old.isSecure != state.get().isSecure){
                    console.log(`Plan changed to ${state.get().isSecure ? "secure" : "nonsecure"}`);
                }
                
                this.render();
            }
        });

        localizer.register(() => {
            this.render();
        });

        planStore.onScheduleLoaded(schedule => {
            this._schedule = schedule;
            this.render();
        });
    }

    public destroy(){
        if (this.container){
            this.container.innerHTML = "";
        }
    }

    public render() {
        if (!this.container || !state.get().amount || !this._schedule || !state.get().numInstallments){
            return;
        }

        utils.recordFirstInteraction(this.container);

        const numInstallments = state.get().numInstallments;
        let schedule = this._schedule.schedules!.filter(s => s.numberOfInstallments == numInstallments)[0];

        if (!schedule || !schedule.elements || schedule.elements.length != numInstallments){
            utils.logCustomError('Cannot create payment schedule, missing data.', { numInstallments, schedule: this._schedule});
            this.container.innerHTML = '';
            return;
        }
        
        const isSecure = state.get().isSecure;

        let template = require('./ui.t.html');
        this.container.innerHTML = template(<PaymentScheduleUiModel>{ 
            localizer,
            installments: schedule.elements.map((s, idx) => {
                let itemModel = <InstallmentUiModel> {
                    amount: utils.formatCurrency(s.chargeAmount, state.getCurrencySymbol(), state.getCurrencyDecimalPlaces()),
                    date: utils.formatDate(s.chargeDate),
                    paymentOrd: localizer.paymentOrd(s.installmentNumber, numInstallments),
                    requiredCredit: utils.formatCurrency(s.requiredCredit, state.getCurrencySymbol(), state.getCurrencyDecimalPlaces())
                };
                return itemModel;
            })
        });

        let trigger = this.container.querySelector('.splitit-ps-h-link-toggle-schedule') as HTMLElement;
        
        let wrapper = this.container.querySelector('.splitit-payment-schedule') as HTMLElement;
        let tableContainer = this.container.querySelector('.splitit-ps-tw') as HTMLElement;
        let headerContainer = this.container.querySelector('.splitit-ps-h') as HTMLElement;

        if (numInstallments > 1){
            let howSplititWorksLink = new HowSplititWorksLink();
            howSplititWorksLink.addTo(headerContainer, 0);
        }

        if (this._expanded){
            wrapper.classList.add('expanded');
        }

        trigger.onclick = (ev) => {
            if (this._expanded){
                wrapper.classList.remove('expanded');
                this._expanded = false;
                frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.paymentScheduleClose());
            } else {
                wrapper.classList.add('expanded');
                this._expanded = true;
                frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.paymentScheduleOpen());
            }
        };
    }
}