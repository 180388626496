import { FLEX_FIELDS_ENV } from '../constants';
import { config } from "../config";
import { InstallmentPickerRenderer } from "./renderers/base";
import { InstallmentPickerTextRenderer } from "./renderers/text";
import { InstallmentPickerButtonRenderer } from "./renderers/buttons";
import { InstallmentPickerSliderRenderer } from "./renderers/slider";
import { InstallmentPickerDropdownRenderer } from "./renderers/dropdown";
import { InstallmentPickerDropdownRendererMobile } from "./renderers/dropdown-mobile";
import { PaymentScheduleRenderer } from "../payment-schedule/ui";
import { state } from "../state";
import { utils } from "../utils";
import * as splititApi from 'splitit-sdk';
import { ApiErrorParser } from '../api-error-parser';
import { apiFactory } from '../api-factory';
import { planStore } from '../services/plan-store';
import { renderLoader } from '../render-utils';
import { frameManager, MessageTypes } from '../frame-manager';
import { TrackerEventPayload } from '../frame-manager-payloads';
import { localizer } from '../localization-provider';
import { LearnMorePopupBuilder, POPUP_EVENTS } from 'splitit-utils';
import { HowSplititWorksLink } from '../ui-components/how-splitit-works-link';

export class InstallmentPicker {
    private _renderer: InstallmentPickerRenderer;
    private _container: HTMLElement;
    private _paymentSchedule: PaymentScheduleRenderer;
    private _pickerContainer: HTMLDivElement;

    private _scheduleLoaded: boolean;
    private _planLoaded: boolean;

    private _ui: string; //Single, Buttons, Slider, Dropdown
    private _amount: number;
    private _options: Array<number>;
    private _initialNumInstallments?: number;
    private _howSplititWorksLink: HowSplititWorksLink;

    constructor() {
        this._ui = null;

        if (config.installmentPicker && config.installmentPicker.ui){
            this._ui = config.installmentPicker.ui;
        }
    }

    public init(){
        if(config.paymentSchedule && config.paymentSchedule.selector){
            let paymentScheduleContainer = document.querySelector(config.paymentSchedule.selector) as HTMLElement;
            this._paymentSchedule = new PaymentScheduleRenderer();
            this._paymentSchedule.container = paymentScheduleContainer;
        }

        if (config.installmentPicker && config.installmentPicker.selector){
            this._container = document.querySelector(config.installmentPicker.selector) as HTMLElement;
            this._container.classList.add('splitit-installment-picker');

            this._pickerContainer = document.createElement('div');
            this._container.appendChild(this._pickerContainer);

            renderLoader(this._pickerContainer);

            utils.recordFirstInteraction(this._container);

            if (!this._paymentSchedule){
                this._paymentSchedule = new PaymentScheduleRenderer();
            }

            if (!this._paymentSchedule.container){
                this._paymentSchedule.container = document.createElement('div');
                this._container.appendChild(this._paymentSchedule.container);
            }
        } 

        planStore.onLoaded(data => {
            this._planLoaded = true;
            this._ui = this._ui ?? data.ui;
            this._amount = data.amount;
            this._initialNumInstallments = data.selectedNumInstallments;

            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.planLoaded());

            this.render();
        });

        planStore.onScheduleLoaded(schedule => {
            this._scheduleLoaded = true;
            this._options = schedule.schedules.map(s => s.numberOfInstallments);
            this.render();
        });

        state.onChange(old => {
            let newState = state.get();
            if (config.installmentPicker && config.installmentPicker.selector){
                if (!utils.areArraysEqual(newState.errors, old.errors)){
                    let markInvalid = newState.errors.filter(e => utils.pickerErrorCodes.includes(e.errorCode)).length > 0;
                    if (markInvalid){
                        this._container.classList.add('invalid');
                    } else {
                        this._container.classList.remove('invalid');
                    }
                }
            }

            if (newState.demoMode && JSON.stringify(old.demoMode) != JSON.stringify(newState.demoMode)){
                this.renderDemoMode();
            }
        });

        if (state.get().demoMode != null){
            this.renderDemoMode();
        }
    }

    public resetForPlanSync(){
        this._planLoaded = false;
        this._scheduleLoaded = false;
        renderLoader(this._pickerContainer);
    }

    public destroy() {
        if (this._paymentSchedule){
            this._paymentSchedule.destroy();
        }

        if (this._container){
            this._container.innerHTML = "";
        }
    }

    public renderDemoMode(){
        this._options = state.get().demoMode.installments;
        this._ui = state.get().demoMode.pickerMode;
        this._amount = state.get().demoMode.amount;
        this._scheduleLoaded = true;
        this._planLoaded = true;
        this.render();
    }

    public render(){
        if (!this._container || !this._planLoaded || !this._scheduleLoaded){
            return;
        }

        state.setNumInstallments(null, this._options);

        this.clearContainer();
        this.initRenderer();
        this._renderer.render(this._pickerContainer);

        this._howSplititWorksLink = new HowSplititWorksLink('splitit-link-how-works-new'); 
        this._howSplititWorksLink.addTo(this._container, 0);
    }

    private initRenderer(){
        let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (this._ui.toLowerCase() == 'single'){
            this._container.classList.add('picker-type-single');
            this._renderer = new InstallmentPickerTextRenderer(this._amount, this._options);
        } else if(this._ui.toLowerCase() == 'buttons'){
            this._container.classList.add('picker-type-buttons');
            this._renderer = new InstallmentPickerButtonRenderer(this._amount, this._options, this._initialNumInstallments);
        } else if(this._ui.toLowerCase() == 'slider') {
            if (isMobile){
                this._container.classList.add('picker-type-dropdown-mobile');
                this._renderer = new InstallmentPickerDropdownRendererMobile(this._amount, this._options, this._initialNumInstallments);
            } else {
                this._container.classList.add('picker-type-slider');
                this._renderer = new InstallmentPickerSliderRenderer(this._amount, this._options, this._initialNumInstallments);
            }
        } else {
            if (isMobile){
                this._container.classList.add('picker-type-dropdown-mobile');
                this._renderer = new InstallmentPickerDropdownRendererMobile(this._amount, this._options, this._initialNumInstallments);
            } else {
                this._container.classList.add('picker-type-dropdown');
                this._renderer = new InstallmentPickerDropdownRenderer(this._amount, this._options, this._initialNumInstallments);
            }
        }
    }

    private clearContainer(){
        this._pickerContainer.innerHTML = '';

        this._howSplititWorksLink && this._howSplititWorksLink.destroy();
        var classesToRemove = [];
        this._container.classList.forEach(c => {
            if (c.startsWith('picker-type')){
                classesToRemove.push(c);
            }
        });

        classesToRemove.forEach(c => {
            this._container.classList.remove(c);
        })
    }
}