import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { config } from "../../config";

export class InstallmentPickerDropdownRendererMobile extends InstallmentPickerRenderer {
    public render(container: HTMLElement) {
        container.classList.add("splitit-installment-picker-dropdown");

        let selectOptions = this.options.map(o => {
            return {
                numPayments: o,
                numPaymentsStr: localizer.numPayments(o),
                amountPerMonth: this.getPaymentCalculationText(o)
            };
        });
        
        let template = require('./dropdown.mobile.t.html');
        container.innerHTML = template({ options: selectOptions });

        let selectElement = container.querySelector('select') as HTMLSelectElement;
        selectElement.onchange = (ev) => {
            this.setSelectedValue(this.options[selectElement.selectedIndex]);
        };

        if (this.initialSelection){
            selectElement.selectedIndex = this.options.findIndex(val => val == this.initialSelection);
        } else {
            selectElement.selectedIndex = Math.trunc(this.options.length / 2);
        }

        this.setSelectedValue(this.options[selectElement.selectedIndex]);
    }

    protected getPickerType() : string{
        return "dropdown-mobile";
    }
}