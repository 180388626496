import { MessageTypes, FrameManagerRoles, frameManager } from '../frame-manager';
import { CollectCardDataPayload, CollectorIntroducePayload, TriggerCollectPayload, TrackerEventPayload, OverrideFieldStylePayload } from '../frame-manager-payloads';
import { state } from '../state';
import { FIELD_TYPES } from './field-types';
import { utils } from '../utils';
import { localizer } from "../localization-provider";
import { DEFAULT_FLAG } from '../constants-ts';

export class FlexField {
    protected _value: string;
    protected _clientError?: string;
    protected _isFocused: boolean;
    protected _isValid: boolean;
    protected _type: string;
    protected _fieldSelector: string;
    protected _guid: string;
    protected _trackBlur: boolean;
    private _collectorId: string;
    private _isDisabled: boolean;
    protected _uiMode?: string;
    protected _everFocused: boolean;
    protected _everNonEmpty: boolean;
    protected _inputElement: HTMLInputElement;

    constructor(guid: string, uiMode?: string) {
        this._trackBlur = true;
        this._guid = guid;
        this._value = null;
        this._isValid = null;
        this._isFocused = false;
        this._everFocused = false;
        this._everNonEmpty = false;
        this._isDisabled = false;
        this._uiMode = uiMode;

        frameManager.init(FrameManagerRoles.FIELD, guid);
        state.reset();
        
        frameManager.subscribe(MessageTypes.INTRODUCE_COLLECTOR, this, this.introduceCollector);
        frameManager.subscribe(MessageTypes.FIELD_STYLE_OVERRIDE, this, this.overrideStyle);
        frameManager.subscribe(MessageTypes.TRIGGER_COLLECT, this, (data: TriggerCollectPayload) => {
            // Make sure we have simulated that field was focused so red border will be shown.
            this._everFocused = true;
            // When data collection is requested, update field validity from state to avoid overwrite with null.
            this._isValid = state.getField(this._type).isValid;

            if (this._type == FIELD_TYPES.EXP_MONTH || this._type == FIELD_TYPES.EXP_YEAR){
                // Mitigate problem that if year is updated maybe month becomes valid, or vice-versa.
                this._isValid = null;
                this._clientError = null;
            }

            this.sendToCollector({commitId: data.commitId});
        });
    }

    protected formatCollectPayload(payload: CollectCardDataPayload) : CollectCardDataPayload {
        return payload;
    }

    protected validate(){
        this._isValid = false;
    }

    public init(fieldSelector: string){
        this._fieldSelector = fieldSelector;
        this._inputElement = document.querySelector(fieldSelector);

        if (this._uiMode && this._uiMode.length > 0){
            this._inputElement.classList.add(this._uiMode);
        }

        localizer.register(() => {
            this._inputElement.setAttribute('placeholder', localizer.getPlaceholderForField(this._type, false, this._uiMode));
        });

        frameManager.notify(MessageTypes.FIELD_INIT, {
            fieldType: this._type
        });

        state.onChange(old => {
            try{
                if (old.isCommiting != state.get().isCommiting){
                    this._isDisabled = state.get().isCommiting;
                    this._inputElement.disabled = this._isDisabled;
                }
    
                let stateInfo = state.getField(this._type);
                if (this._type != FIELD_TYPES.NUMBER && stateInfo && stateInfo.isValid != true){
                    // If someone fills exp. date before CC number, after CC number is validated as success, 
                    // we want to validate this field also
                    var newNumberField = state.getField(FIELD_TYPES.NUMBER);
    
                    if (newNumberField && newNumberField.isValid == true){
                        var oldNumberField = old.fields.filter(f => f.type == FIELD_TYPES.NUMBER)[0];
                        if (oldNumberField.isValid != true){
                            // CC number field was validated
                            this.sendToCollector({ requireServerValidation: true});
                        }
                    }
                }
    
                if (this._type == FIELD_TYPES.NUMBER && stateInfo &&
                    //(stateInfo.errors.length > 0 || !state.get().isSecure || state.get().errors.filter(e => utils.pickerErrorCodes.includes(e.errorCode)).length > 0) && 
                    old.numInstallments != state.get().numInstallments) {
                    this.sendToCollector({ requireServerValidation: true });
                }
            } catch (e){
                utils.logException(e);
            }
        });
    }

    // Invoked from formfield.html
    public updateFocus(isFocused) {
        try{
            this._everFocused = this._everFocused || isFocused;

            this._inputElement.setAttribute('placeholder', localizer.getPlaceholderForField(this._type, isFocused, this._uiMode));

            if (this._isFocused != isFocused) {
                this._isFocused = isFocused;
            }
    
            if (!isFocused){
                frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.fieldBlur(this._type, this.getExposableValue(), this._clientError));
                
                // Blur happened
                setTimeout(() => {
                    // Give some time for any "change" event to fire in the meantime
                    this.validate();
                    this.sendToCollector({requireServerValidation: true});
                }, 35);
            } else {
                this.sendToCollector();
                frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.fieldFocus(this._type, this.getExposableValue()));
            }
        } catch (e){
            utils.logException(e);
        }
    }

    // Invoked from formfield.html
    public updateChange(newValue: string){
        this._value = newValue;
        if (this._value && this._value.length > 0){
            this._everNonEmpty = true;
        }

        // Validation and everything handled by blur event
    }

    // Invoked from formfield.html
    public updateKeypress(newValue: string){
        this._isValid = null;
        this._clientError = null;
        this._value = newValue;
    }

    protected getExposableValue(){
        if (this._value && this._value != ''){
            return "*".repeat(this._value.length);
        } else {
            return null;
        }
    }

    protected sendToCollector(p?: {requireServerValidation?: boolean, commitId?: string}){
        p = Object.assign({}, p);
       
        this.validate();

        let payload = new CollectCardDataPayload();
        payload.fieldType = this._type;
        payload.value = this._value;
        payload.isFocused = this._isFocused;
        payload.isValid = this._isValid;
        payload.showValidationError = !this._isFocused && this._everFocused && this._everNonEmpty;
        payload.requireServerSideValidation = p.requireServerValidation;
        payload.clientError = this._clientError;
        payload.isDisabled = this._isDisabled;
        payload.commitId = p.commitId;

        payload = this.formatCollectPayload(payload);

        frameManager.notifySecure(MessageTypes.COLLECT_CARD_DATA, payload, this._collectorId);
    }

    private introduceCollector(data: CollectorIntroducePayload){
        this._collectorId = data._originId;
    }

    private overrideStyle(data: OverrideFieldStylePayload){
        if (data.style.fontFamily && data.style.fontFamily != ''){
            this._inputElement.style.fontFamily = data.style.fontFamily;
        }

        if (data.style.fontSize && data.style.fontSize != ''){
            this._inputElement.style.fontSize = data.style.fontSize;
        }

        if (data.style.textColor && data.style.textColor != ''){
            if (data.style.textColor == DEFAULT_FLAG){
                this._inputElement.style.removeProperty('color');
            } else {
                this._inputElement.style.color = data.style.textColor;
            }
        }

        if (data.style.placeholderTextColor && data.style.placeholderTextColor != ''){
            var keys = [
                'input::-webkit-input-placeholder',
                'input::-ms-input-placeholder',
                'input:-ms-input-placeholder',
                'input::-moz-placeholder',
                'input:-moz-placeholder',
                'input::placeholder'
            ];

            var escaped = data.style.placeholderTextColor.replace(/[^a-zA-Z0-9\(\)#\.]/g, '');

            var style = document.getElementById('field-style-override') as HTMLStyleElement;
            var sheet = style.sheet as CSSStyleSheet;
            var rule = `color: ${escaped}`;

            keys.forEach(k => {
                try
                {
                    if (utils.isShitholeBrowser()){
                        sheet.addRule(k, rule);
                    } else {
                        sheet.insertRule(`${k}{${rule}}`);
                    }
                } catch(_){}
            })
        }
    }
};