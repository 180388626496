import { InitiateInstallmentsPlanResponse, ModelError, InitiateInstallmentsPlanResponseFromJSONTyped, PlanStrategy, ReferenceEntityBase } from "splitit-sdk";
import { frameManager, MessageTypes } from "./frame-manager";
import { SplititStateSyncPayload } from "./frame-manager-payloads";
import { utils } from "./utils";
import { IErrorDescriptor, ErrorMapper, ErrorCodes } from "./error-mapper";
import { FieldDescriptorInfo } from "./ui-components/field-descriptor";
import { config } from "./config";
import currencyToSymbolMap from 'currency-symbol-map/map';

const clone = require('rfdc')();

type InstallmentPickerUiType = "slider" | "dropdown" | "buttons" | "single";

export class DemoState {
    public installments: Array<number>;
    public pickerMode: InstallmentPickerUiType;
    public amount: number = 1000;
}

export interface IPartialSplititState{
    publicToken?: string;
    refOrderNumber?: string;
    ipn?: string;
    culture?: string;
    termsConditionsUrl?: string;
    privacyPolicyUrl?: string;
    termsAccepted?: boolean;
    numInstallments?: number;
    amount?: number;
    currencyCode?: string;
    isSecure?: boolean;
    isCommiting?: boolean;
    demoMode?: DemoState;
    isSandboxApi?: boolean;
    firstInstallmentAmount?: number;
    firstChargeDate?: Date;
    currencyDecimalPlaces?: number;
    is3ds2Supported?: boolean;
    processorName?: string;
    errorRedirectUrl?: string;
    successRedirectUrl?: string;
};

export interface ISplititState{
    publicToken: string;
    ipn: string;
    refOrderNumber?: string;
    culture?: string;
    termsConditionsUrl: string;
    privacyPolicyUrl: string;
    termsAccepted: boolean;
    numInstallments: number;
    installmentOptions: Array<number>;
    amount: number;
    firstInstallmentAmount?: number;
    firstChargeDate?: Date;
    currencyCode: string;
    isSecure: boolean;
    fields: Array<FieldDescriptorInfo>;
    errors: Array<ModelError>;
    isCommiting: boolean;
    demoMode?: DemoState;
    isSandboxApi: boolean;
    currencyDecimalPlaces: number;
    is3ds2Supported: boolean;
    processorName?: string;
    errorRedirectUrl?: string;
    successRedirectUrl?: string;
};

export interface IStateActiveErrors {
    isChanged?: boolean;
    errors: Array<IErrorDescriptor>;
    hasErrors: boolean;
    hasGeneralErrors: boolean;
};

class SplititState {
    private _state: ISplititState;

    private _onChangeCallbacks: Array<(old: ISplititState) => void>;
    private _useFrameManager: boolean;

    constructor(){
        this.reset();
    }

    public reset(useFrameManager: boolean = true) {
        this._useFrameManager = useFrameManager;
        this._onChangeCallbacks = [];
        this._state = {
            publicToken: null,
            refOrderNumber: null,
            culture: null,
            ipn: null,
            numInstallments: null,
            installmentOptions: [],
            amount: null,
            currencyCode: null,
            termsConditionsUrl: null,
            privacyPolicyUrl: null,
            termsAccepted: false,
            fields: [],
            errors: [],
            isCommiting: false,
            demoMode: null,
            isSecure: true,
            isSandboxApi: false,
            currencyDecimalPlaces: 2,
            is3ds2Supported: false,
            processorName: null,
            errorRedirectUrl: null,
            successRedirectUrl: null
        };

        if (useFrameManager){
            frameManager.subscribe(MessageTypes.STATE_SYNC, this, (payload: SplititStateSyncPayload) => {
                this.set(payload.state, false, payload.changedProps);
            });
        }
    }

    public getCurrencySymbol() : string {
        if (config.currencySymbol){
            return config.currencySymbol;
        }

        return currencyToSymbolMap[this._state.currencyCode];
    }

    public getCurrencyDecimalPlaces(): number {
        return this._state.currencyDecimalPlaces;
    }

    public parseResponse(data: InitiateInstallmentsPlanResponse){
        let old = clone(this._state);

        this._state.publicToken = data.publicToken;
        this.fireOnChange(old);
    }

    public parseResponsePascalCase(dataPascal: any){
        let old = clone(this._state);

        let data = InitiateInstallmentsPlanResponseFromJSONTyped(dataPascal, false);
        this._state.publicToken = data.publicToken;
        this.fireOnChange(old);
    }

    public parseUnknown(data: any){
        let old = clone(this._state);

        if(data.publicToken){
            this._state.publicToken = data.publicToken;
        }

        this.fireOnChange(old);
    }

    public onChange(callback: (old: ISplititState) => void) : (old: ISplititState) => void {
        this._onChangeCallbacks.push(callback);
        return callback;
    }

    public removeListener(callback: (old: ISplititState) => void) {
        const index = this._onChangeCallbacks.indexOf(callback, 0);
        if (index > -1) {
            this._onChangeCallbacks.splice(index, 1);
        }
    }

    public addField(field: FieldDescriptorInfo){
        let old = clone(this._state);
        this._state.fields.push(field);
        this.fireOnChange(old, true, ['fields']);
    }

    public updateField(field: FieldDescriptorInfo){
        let old = clone(this._state);
        this._state.fields.forEach(f => {
            if (f.type == field.type){
                Object.assign(f, field);
            }
        });

        this.fireOnChange(old, true, ['fields.update']);
    }

    public setIsSandbox(isSandbox: boolean) {
        let old = clone(this._state);
        this._state.isSandboxApi = isSandbox;
        this.fireOnChange(old, true, ['isSandboxApi']);
    }

    public setCulture(culture: string){
        let old = clone(this._state);
        this._state.culture = culture;
        this.fireOnChange(old, true, ['culture']);
    }

    public setPublicToken(token: string){
        if (!token || token === ''){
            if (config.isDebugMode()){
                console.warn('[Splitit] Potential configuration error: trying to set empty public token.');
            }
        }

        let old = clone(this._state);
        this._state.publicToken = token;
        this.fireOnChange(old, true, ['publicToken']);
    }

    public setAmount(amount: number){
        let old = clone(this._state);
        this._state.amount = amount;
        this.fireOnChange(old, true, ['amount']);
    }

    public setCurrencyCode(code: string){
        let old = clone(this._state);
        this._state.currencyCode = code;
        this.fireOnChange(old, true, ['currencyCode']);
    }

    public setStrategy(strategy: string | PlanStrategy | ReferenceEntityBase) {
        let old = clone(this._state);
        if (strategy == PlanStrategy.NonSecuredPlan || (<ReferenceEntityBase>strategy).code == PlanStrategy.NonSecuredPlan){
            this._state.isSecure = false;
        } else {
            this._state.isSecure = true;
        }
        this.fireOnChange(old, true, ['isSecure']);
    }

    public setTermsConditionsUrl(url: string){
        let old = clone(this._state);
        this._state.termsConditionsUrl = url;
        this.fireOnChange(old, true, ['termsConditionsUrl']);
    }

    public setPrivacyPolicyUrl(url: string){
        let old = clone(this._state);
        this._state.privacyPolicyUrl = url;
        this.fireOnChange(old, true, ['privacyPolicyUrl']);
    }

    public setIPN(ipn: string){
        let old = clone(this._state);
        this._state.ipn = ipn;
        this.fireOnChange(old, true, ['ipn']);
    }

    public setNumInstallments(num: number, options?: Array<number>){
        let old = clone(this._state);
        this._state.numInstallments = num;
        var changedProps = ['numInstallments'];

        if (options){
            this._state.installmentOptions = options;
            changedProps.push('installmentOptions');
        }

        this.fireOnChange(old, true, changedProps);
    }

    public setTermsAccepted(termsAccepted: boolean){
        let old = clone(this._state);
        this._state.termsAccepted = termsAccepted;
        var propsChanged = ['termsAccepted'];
        
        if (this._state.errors && this._state.errors.filter(e => e.errorCode == ErrorCodes.TERMS_NOT_SELECTED).length > 0){
            this._state.errors = this._state.errors.filter(e => e.errorCode != ErrorCodes.TERMS_NOT_SELECTED);
            propsChanged.push('errors');
        }
        
        this.fireOnChange(old, true, propsChanged);
    }

    public setIsCommiting(isCommiting: boolean){
        let old = clone(this._state);
        this._state.isCommiting = isCommiting;
        this.fireOnChange(old, true, ['isCommiting']);
    }

    public setDemo(demoState: DemoState){
        let old = clone(this._state);
        this._state.demoMode = demoState;
        this.fireOnChange(old, true, ['demoMode']);
    }

    public setErrors(errors: Array<ModelError>){
        let old = clone(this._state);
        this._state.errors = errors;
        this.fireOnChange(old, true, ['errors']);
    }

    public getActiveErrors(old?: ISplititState) : IStateActiveErrors{
        let newState = this._state;
        var newStateErrors = ErrorMapper.combine(newState.errors, newState.fields);

        let result = <IStateActiveErrors>{
            errors: newStateErrors,
            hasErrors: newStateErrors.length > 0,
            hasGeneralErrors: newStateErrors.filter(e => e.fieldTypes == null || e.fieldTypes.length == 0).length > 0
        };

        if (old){
            var oldStateErrors = ErrorMapper.combine(old.errors, old.fields);
            if (!utils.areArraysEqual(newStateErrors.map(e => e.key + '|' + e.showError), oldStateErrors.map(e => e.key + '|' + e.showError))){
                result.isChanged = true;
            } else {
                result.isChanged = false;
            }
        }

        return result;
    }

    public get() : ISplititState{
        return this._state;
    }

    public getField(fieldType: string) : FieldDescriptorInfo {
        let fields = this._state.fields.filter(f => f.type == fieldType);
        if (fields.length == 0){
            return null;
        }

        return fields[0];
    }

    public set(data: ISplititState, notifyOtherFrames: boolean = true, changedProps?: Array<string>){
        let old = clone(this._state);

        if (changedProps){  
            changedProps.forEach(p => {
                //console.log('Updating state for property ' + p);
                if (p == 'fields.update'){
                    this._state.fields.forEach(thisField => {
                        var incomingField = data.fields.filter(f => f.type == thisField.type)[0];
                        if (incomingField){
                            Object.assign(thisField, incomingField);
                        }
                    });
                } else {
                    this._state[p] = data[p];
                }
                
            });
        } else {
            this._state = data;
        }
        
        this.fireOnChange(old, notifyOtherFrames);
    }

    public setPartial(data: IPartialSplititState){
        let old = clone(this._state);
        this._state.publicToken = data.publicToken ?? this._state.publicToken;
        this._state.ipn = data.ipn ?? this._state.ipn;
        this._state.culture = data.culture ?? this._state.culture;
        this._state.termsConditionsUrl = data.termsConditionsUrl ?? this._state.termsConditionsUrl;
        this._state.privacyPolicyUrl = data.privacyPolicyUrl ?? this._state.privacyPolicyUrl;
        this._state.termsAccepted = data.termsAccepted ?? this._state.termsAccepted;
        this._state.numInstallments = data.numInstallments ?? this._state.numInstallments;
        this._state.amount = data.amount ?? this._state.amount;
        this._state.currencyCode = data.currencyCode ?? this._state.currencyCode;
        this._state.isSecure = data.isSecure ?? this._state.isSecure;
        this._state.isCommiting = data.isCommiting ?? this._state.isCommiting;
        this._state.demoMode = data.demoMode ?? this._state.demoMode;
        this._state.isSandboxApi = data.isSandboxApi ?? this._state.isSandboxApi;
        this._state.firstChargeDate = data.firstChargeDate ?? this._state.firstChargeDate;
        this._state.firstInstallmentAmount = data.firstInstallmentAmount ?? this._state.firstInstallmentAmount;
        this._state.currencyDecimalPlaces = data.currencyDecimalPlaces ?? this._state.currencyDecimalPlaces;
        this._state.is3ds2Supported = data.is3ds2Supported != null ? data.is3ds2Supported! : this._state.is3ds2Supported;
        this._state.processorName = data.processorName ?? this._state.processorName;
        this._state.refOrderNumber = data.refOrderNumber ?? this._state.refOrderNumber;
        this._state.errorRedirectUrl = data.errorRedirectUrl ?? this._state.errorRedirectUrl;
        this._state.successRedirectUrl = data.successRedirectUrl ?? this._state.successRedirectUrl;

        this.fireOnChange(old, true, [
            'publicToken', 'ipn', 'culture', 'termsConditionsUrl',
            'privacyPolicyUrl', 'termsAccepted', 'numInstallments', 
            'amount', 'currencyCode', 'isSecure', 'isCommiting', 'demoMode',
            'isSandboxApi', 'firstChargeDate', 'firstInstallmentAmount', 
            'is3ds2Supported', 'processorName', 'refOrderNumber',
            'errorRedirectUrl', 'successRedirectUrl'
        ]);
    }

    private fireOnChange(old: ISplititState, notifyOtherFrames: boolean = true, changedProps?: Array<string>){
        //console.log(`state callbacks on ${document.location.href}: ${this._onChangeCallbacks.length}`);
        this._onChangeCallbacks.forEach(c => c.call(this, old));

        if (notifyOtherFrames && this._useFrameManager){
            frameManager.notify(MessageTypes.STATE_SYNC, <SplititStateSyncPayload>{
                state: this.get(),
                changedProps: changedProps
            });
        }
    }
}

const state = new SplititState();
export { state };