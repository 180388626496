import { FieldInitPayload } from "../frame-manager-payloads";
import { state } from "../state";
import {VERSION} from '../constants';
import { FieldDescriptorInfo } from "./field-descriptor";
import { getFieldUrlForType, FIELD_TYPES } from "../fields/field-types";
import { utils } from "../utils";
import { config, FieldStyleSection } from "../config";
import { frameManager, MessageTypes } from "../frame-manager";
import { localizer } from "../localization-provider";
import { UI_MODE_HORIZONTAL, DEFAULT_FLAG } from "../constants-ts";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

interface UpdateResult {
    isChanged: boolean;
}

export class FieldWrapper {
    public frameId?: string;
    public isInitialized: boolean;
    public wrapperContainer?: HTMLElement;
    public fieldContainer?: HTMLElement;
    public errorContainer?: HTMLElement;
    private horizontalModeWrapper?: HTMLElement;
    public selector: string;
    public type: string;
    private _isDestroyed: boolean;

    constructor(selector: string, type: string){
        this._isDestroyed = false;
        this.selector = selector;
        this.type = type;
        this.isInitialized = false;
    }

    public init(data: FieldInitPayload) {
        this.frameId = data._originId;
        this.wrapperContainer = document.querySelector(this.selector);
        if (this.wrapperContainer){
            var parent1 = this.wrapperContainer.parentElement;
            if (parent1.classList.contains('splitit-horizontal')){
                this.horizontalModeWrapper = parent1;
            } else if(parent1.parentElement.classList.contains('splitit-horizontal')){
                this.horizontalModeWrapper = parent1.parentElement;
            }
        }
        
        this.fieldContainer = document.querySelector('.splitit-flex-field.splitit-flex-field-' + this.type);
        this.errorContainer = document.querySelector('.splitit-flex-field-error.splitit-flex-field-error-' + this.type);
        this.isInitialized = true;

        utils.recordFirstInteraction(this.wrapperContainer);

        state.addField(<FieldDescriptorInfo>{
            errors: [],
            showValidationError: false,
            type: this.type
        });

        var fieldStyle = new FieldStyleSection();
        fieldStyle.overrideOptions(config.fields.style); // override with global options

        // Override with field-specific settings
        if (config.fields[this.type] && config.fields[this.type].style){
            fieldStyle.overrideOptions(config.fields[this.type].style);
        }

        if (fieldStyle.hasOverrides()){
            frameManager.notify(MessageTypes.FIELD_STYLE_OVERRIDE, { style: fieldStyle }, this.frameId);
        }

        state.onChange(old => {
            const newState = state.get();
            const fieldErrors = state.getActiveErrors(old).errors.filter(e => e.fieldTypes && e.fieldTypes.indexOf(this.type) >= 0 && e.showError);

            let oldField = old.fields.filter(f => f.type == this.type)[0];
            let newField = newState.fields.filter(f => f.type == this.type)[0];

            if (newField && oldField && newField.cardType != oldField.cardType){
                this.fieldContainer.setAttribute('data-card', newField.cardType);
            }

            if (!newField){
                console.error("Why is this null???");
            }

            var hzWrapperClassListBefore = null;
            if (this.horizontalModeWrapper){
                hzWrapperClassListBefore = this.horizontalModeWrapper.classList.value;
            }

            if (newField && newField.isValid === true){
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.remove(`invalid-${this.type}`);
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.add(`valid-${this.type}`);
                this.fieldContainer.classList.remove('invalid');
                this.fieldContainer.classList.add('valid');
            } else if(newField && newField.isValid === false && newField.showValidationError){
                this.fieldContainer.classList.remove('valid');
                this.fieldContainer.classList.add('invalid');
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.remove(`valid-${this.type}`);
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.add(`invalid-${this.type}`);
            } else {
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.remove(`valid-${this.type}`);
                this.horizontalModeWrapper && this.horizontalModeWrapper.classList.remove(`invalid-${this.type}`);
                this.fieldContainer.classList.remove('valid');
                this.fieldContainer.classList.remove('invalid');
            }

            if (this.horizontalModeWrapper && this.horizontalModeWrapper.classList.value != hzWrapperClassListBefore){
                if (this.horizontalModeWrapper.classList.contains(`invalid-${this.type}`)){
                    frameManager.notify(MessageTypes.FIELD_STYLE_OVERRIDE, { style: { textColor: 'red' } }, this.frameId);
                } else if(fieldStyle.textColor) {
                    frameManager.notify(MessageTypes.FIELD_STYLE_OVERRIDE, { style: { textColor: fieldStyle.textColor } }, this.frameId);
                } else {
                    frameManager.notify(MessageTypes.FIELD_STYLE_OVERRIDE, { style: { textColor: DEFAULT_FLAG } }, this.frameId);
                }
            }

            if (newField && newField.isFocused) {
                this.fieldContainer.classList.add('focused');
            } else {
                this.fieldContainer.classList.remove('focused');
            }

            if (newField && newField.hasContent) {
                this.fieldContainer.classList.add('has-content');
            } else {
                this.fieldContainer.classList.remove('has-content');
            }

            if (fieldErrors.length > 0){
                this.errorContainer.innerHTML = "* " + fieldErrors[0].error;
                this.errorContainer.classList.remove("splitit-hidden");
            } else {
                this.errorContainer.classList.add("splitit-hidden");
            }
        })
    }

    render() {
        this.wrapperContainer = document.querySelector(this.selector);
        this.wrapperContainer.classList.add("splitit-flex-field-wrapper");
        this.wrapperContainer.classList.add("splitit-flex-field-wrapper-" + this.type);

        let url = getFieldUrlForType(this.type);
        if (url.indexOf('?') > 0){
            url += '&v=' + VERSION;
        } else {
            url += '?v=' + VERSION;
        }

        if (config.getUiMode()){
            url += '&ui-mode=' + config.getUiMode();
        }

        let template = require('./flex-field.t.html');
        this.wrapperContainer.innerHTML = template({
            url: url, 
            type: this.type,
            labelText: localizer.getLabelForField(this.type),
            showSandboxWarning: this.type == FIELD_TYPES.NUMBER && state.get().isSandboxApi
        });

        if (this.type == FIELD_TYPES.NUMBER || this.type == FIELD_TYPES.EXP_DATE || this.type == FIELD_TYPES.CVV){
            let tooltipInstance = tippy(`${this.selector} .tooltip-icon-container`, {
                appendTo: () => this.wrapperContainer,
                placement: 'bottom-end',
                theme: 'light-border',
                maxWidth: 180,
                offset: [0, -1]
            })[0];

            localizer.register(() => {
                if (!this._isDestroyed){
                    tooltipInstance.setContent(localizer.getTooltipForField(this.type));
                }
            });
        }

        if (this.type == FIELD_TYPES.NUMBER || this.type == FIELD_TYPES.EXP_DATE || this.type == FIELD_TYPES.CVV){
            if (config.getUiMode() == UI_MODE_HORIZONTAL){
                var errorBox = document.querySelector('.splitit-horizontal-cc-error-box');
                if (errorBox){
                    errorBox.appendChild(this.wrapperContainer.querySelector('.splitit-flex-field-error'));
                }
            }
        }

        localizer.register(() => {
            if (!this._isDestroyed){
                var label = this.wrapperContainer.getElementsByTagName('label')[0];
                if (label){
                    label.innerText = localizer.getLabelForField(this.type);
                }
            }
        });
    }

    destroy() {
        this._isDestroyed = true;

        let container = document.querySelector(this.selector);
        if (container){
            container.innerHTML = "";
        }

        var errorBox = document.querySelector('.splitit-horizontal-cc-error-box');
        if (errorBox){
            errorBox.innerHTML = '';
        }
    }
}