import { state } from "./state";
import { ModelError, ModelErrorFromJSON } from "splitit-sdk";

export class ApiErrorParser{
    private _err: any;
    private _errorList: Array<any>;
    public require3D: boolean;
    public possibleConcurrencyError: boolean;

    constructor(err: any){
        this._err = err;
        this._errorList = [];

        if (err && err.responseHeader && err.responseHeader.errors){
            this._errorList = err.responseHeader.errors;
        }
    }

    public parse(autoUpdateState: boolean) : Array<ModelError> {
        let errorList = new Array<ModelError>();

        if (this._errorList) {
            if (this._errorList.length == 1 && this._errorList[0].ErrorCode == '641') {
                this.require3D = true;
            } else if (this._errorList.length == 1 && this._errorList[0].ErrorCode == '5004') {
                this.possibleConcurrencyError = true;
            } else {
                errorList = this._errorList.map(p => ModelErrorFromJSON(p));
            }
        } else if (this._err && this._err.status && this._err.type) {
            errorList = [{
                message: 'HTTP error has ocurred',
                errorCode: '00H'
            }];
        } else {
            errorList = [{
                message: 'Unknown error has ocurred',
                errorCode: '00U'
            }];
            console.error(this._errorList);
        }

        if (autoUpdateState){
            state.setErrors(errorList);
        }

        return errorList;
    }
}