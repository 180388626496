import { ISplititState, state, IStateActiveErrors } from "./state";
import { FIELD_TYPES } from "./fields/field-types";
import { IErrorDescriptor } from "./error-mapper";
import { FieldStyleSection } from "./config";
import { EventFactory, EVENT_FIELD, ISplititError, ITrackingEvent, IUIStateInfo } from "splitit-utils";

export class PayloadBase {
    public _originId: string;
}

export class CollectCardDataPayload extends PayloadBase {
    public fieldType: string;
    public value: string;
    public isValid: boolean;
    public showValidationError: boolean;
    public isFocused: boolean;
    public cardType: string;
    public clientError: string;
    public requireServerSideValidation: boolean;
    public commitId?: string;
    public forter?: string;
    public isDisabled: boolean;
}

export class FieldInitPayload extends PayloadBase {
    public fieldType: string;
}

export class TriggerCollectPayload extends PayloadBase {
    public collectorId: string;
    public commitId: string;
}

export class LocalizationPayload extends PayloadBase {
    resources: { [key: string]: string; };
}


export class ServerSideValidationPayload extends PayloadBase {
    public originalValue: string;
    public isValid: boolean;
    public error: string;
}

export class CollectorIntroducePayload extends PayloadBase {
}

export class OverrideFieldStylePayload extends PayloadBase {
    public style: FieldStyleSection;
}

export class SplititStateSyncPayload extends PayloadBase {
    public state: ISplititState;
    public changedProps?: Array<string>;
}

export class TrackerInitPayload extends PayloadBase {
    public merchantUrl: string;
    public uiLayout: string;
    public config?: any;
    public design: string;
    public readyAt?: Date;
    public createdAt?: Date;
}

let eventFieldMap = {};
eventFieldMap[FIELD_TYPES.NUMBER] = 'CC Number';
eventFieldMap[FIELD_TYPES.CVV] = 'CVV';
eventFieldMap[FIELD_TYPES.EXP_DATE] = 'Expiration Date';
eventFieldMap[FIELD_TYPES.EXP_MONTH] = 'Expiration Month';
eventFieldMap[FIELD_TYPES.EXP_YEAR] = 'Expiration Year';
eventFieldMap[FIELD_TYPES.CARDHOLDER_NAME] = 'Cardholder Name';

export class TrackerEventPayload extends PayloadBase {
    public event: string;
    public label?: string;
    public category: string;
    public value?: any;
    public eventData?: ITrackingEvent;

    public static fieldBlur(fieldType: string, extraValue?: string, clientError?: string) : TrackerEventPayload {
        let clientErrors = [];
        if (clientError){
            clientErrors.push(clientError);
        }

        return <TrackerEventPayload>{
            category: 'Payment_Details',
            event: eventFieldMap[fieldType] + ' Change Value',
            eventData: EventFactory.InputBlur(fieldType, extraValue, clientErrors, TrackerEventPayload.getUiStateInfo())
        };
    }

    public static fieldFocus(fieldType: string, extraValue?: string) : TrackerEventPayload {
        return <TrackerEventPayload>{
            category: 'Payment_Details',
            event: eventFieldMap[fieldType] + ' Click',
            eventData: EventFactory.InputFocus(fieldType, extraValue, TrackerEventPayload.getUiStateInfo())
        };
    }

    public static pickerDropdownOpen() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Open',
            category: 'Installment_Picker',
            eventData: EventFactory.PickerDropdownOpen(TrackerEventPayload.getUiStateInfo(), null, { 'action': 'open' })
        };
    }

    public static pickerDropdownClose() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Close',
            category: 'Installment_Picker',
            eventData: EventFactory.PickerDropdownOpen(TrackerEventPayload.getUiStateInfo(), null, { 'action': 'close' })
        };
    }

    public static installmentPickerChanged(p: {
        oldValue: any, 
        newValue: any,
        options: Array<any>,
        pickerType: string
    }) : TrackerEventPayload {
        return <TrackerEventPayload>{
            category: 'Installment_Picker',
            event: 'Selection/Click',
            label: `${p.pickerType} [${p.options}]: ${p.oldValue}->${p.newValue}`,
            value: p.newValue,
            eventData: EventFactory.PickerChange(p.oldValue, p.newValue, p.pickerType, TrackerEventPayload.getUiStateInfo())
        };
    }

    public static termsLinkClick() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Click T&Cs Link',
            category: 'Legal',
            eventData: EventFactory.OpenTermsConditions(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static termsPopupClose() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Close T&Cs Popup',
            category: 'Legal',
            eventData: EventFactory.CloseDialog(TrackerEventPayload.getUiStateInfo(), "T&C popup")
        };
    }

    public static privacyLinkClick() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Click Privacy Policy Link',
            category: 'Legal',
            eventData: EventFactory.OpenPrivacyPolicy(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static privacyPopupClose() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Close PP Popup',
            category: 'Legal',
            eventData: EventFactory.CloseDialog(TrackerEventPayload.getUiStateInfo(), "PP popup")
        };
    }

    public static termsCheckboxChanged(isChecked: boolean) : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: isChecked ? 'Agreement Checkbox Select' : 'Agreement Checkbox Deselect',
            category: 'Legal',
            eventData: isChecked ? EventFactory.TermsAccepted(TrackerEventPayload.getUiStateInfo()) : EventFactory.TermsDeclined(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static planLoaded(): TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Fields View',
            category: 'FormLoad',
            eventData: EventFactory.PlanLoaded(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static paymentScheduleOpen() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Expand Button Click',
            category: 'Payment_Schedule',
            eventData: EventFactory.OpenDropdownPaymentSchedule(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static paymentScheduleClose() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Compress Button Click',
            category: 'Payment_Schedule',
            eventData: EventFactory.CloseDropdownPaymentSchedule(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static howSplititWorksOpen() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Click Link',
            category: 'How Splitit Works',
            eventData: EventFactory.OpenLearnMore(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static howSplititWorksClosed() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'Close',
            category: 'How Splitit Works',
            eventData: EventFactory.CloseDialog(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static checkout() : TrackerEventPayload {
        var cardType = state.getField(FIELD_TYPES.NUMBER).cardType;
        return <TrackerEventPayload>{
            event: 'Pay Button Click',
            category: 'Checkout',
            label: `card:${cardType}`,
            eventData: EventFactory.PayButtonClick(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static generalErrorShown(errorState: IStateActiveErrors) : TrackerEventPayload {
        var errorStr = errorState.errors
            .filter(e => e.fieldTypes == null || e.fieldTypes.length == 0)
            .map(e => e.key.length > 15 ? e.key.substr(0,15) : e.key)
            .join("|");

        return <TrackerEventPayload>{
            category: 'Incomplete_Confirmaiton_Details',
            label: `${errorStr}`,
            event: 'General Error Message Display',
            eventData: EventFactory.GeneralErrorShown(errorState.errors.map(s => <ISplititError>{ code: s.code, message: s.error }), TrackerEventPayload.getUiStateInfo())
        };
    }

    public static fieldErrorShown(errors: IErrorDescriptor[]) : TrackerEventPayload {
        var errorStr = errors
            .filter(e => e.fieldTypes && e.fieldTypes.length > 0)
            .filter(e => e.showError)
            .map(e => e.fieldTypes.join(',') + ':' + (e.key.length > 15 ? e.key.substr(0,15) + '...' : e.key))
            .join("|");

        return <TrackerEventPayload>{
            category: 'Incomplete_Confirmaiton_Details',
            label: `${errorStr}`,
            event: 'Field Error Message Display'
        };
    }

    public static userInteraction() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: 'First User Interaction',
            category: 'FormLoad',
            eventData: EventFactory.FirstUserInteraction(TrackerEventPayload.getUiStateInfo())
        };
    }

    public static shown(isNewFlow: boolean) : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: `Initial Load`,
            category: 'FormLoad',
            label: isNewFlow ? 'recommended-flow' : 'custom-flow',
            eventData: EventFactory.FormShown(TrackerEventPayload.getUiStateInfo(), { flow: isNewFlow ? 'recommended-flow' : 'custom-flow' })
        };
    }

    public static hidden() : TrackerEventPayload {
        return <TrackerEventPayload>{
            event: `Hidden`,
            category: 'System',
        };
    }

    public static getUiStateInfo() : IUIStateInfo {
        return <IUIStateInfo>{
            amount: state.get().amount,
            currencyCode: state.get().currencyCode,
            selectedNumOfInstallments: state.get().numInstallments,
            installmentOptions: state.get().installmentOptions
        };
    }
}