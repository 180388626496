import * as splititApi from 'splitit-sdk';
import { SplititClientInfo } from 'splitit-utils';
import { state } from './state';

class ApiFactory {
    public getPlanApi() : splititApi.InstallmentPlanApi{
        if (state.get().isSandboxApi) {
            splititApi.Configuration.setSandbox();
        }

        splititApi.Configuration.setTouchPoint({
            code: "FlexFields",
            version: "v1.1"
        });

        var config = new splititApi.Configuration({
            headers: new SplititClientInfo().header,
            accessToken: state.get().publicToken
        });

        let installmentPlanApi = new splititApi.InstallmentPlanApi(config);
        if (state.get().culture){
            installmentPlanApi.setCulture(state.get().culture);
        }

        return installmentPlanApi;
    }

    public getInfrastructureApi() : splititApi.InfrastructureApi {
        if (state.get().isSandboxApi) {
            splititApi.Configuration.setSandbox();
        }

        return new splititApi.InfrastructureApi(splititApi.Configuration.clientSide(null));
    }
}

const apiFactory = new ApiFactory();
export {apiFactory};