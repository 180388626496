import { getCollectorUrl, VERSION, FLEX_FIELDS_ENV } from './constants';
import { frameManager, MessageTypes, FrameManagerRoles, FrameNames } from './frame-manager';
import { appendHiddenFrame,  render3dModalIframe, hide3dModal, IModal3dsIframeParams, IModal3dsIframeResult } from './render-utils';
import { FIELD_TYPES } from './fields/field-types';
import { FieldInitPayload, TriggerCollectPayload, TrackerInitPayload, TrackerEventPayload, LocalizationPayload } from './frame-manager-payloads';
import { FieldWrapper } from './ui-components/flex-field';
import { config } from './config';
import { InstallmentPicker } from './picker/installment-picker';
import { state, IStateActiveErrors, DemoState } from './state';
import { TermsConditionsLink } from './ui-components/terms-link';
import { ModelError, PlanStrategyFromJSONTyped } from 'splitit-sdk';
import { utils } from './utils';
import { ErrorBox } from './ui-components/error-box';
import { PaymentButton } from './ui-components/payment-button';
import { localizer } from './localization-provider';
import { ErrorCodes, IErrorDescriptor } from './error-mapper';
import { FieldDescriptorInfo } from './ui-components/field-descriptor';
import { IUpdateDetailsData } from './models/update-details-model';
import { shopperDetailsStore } from './services/shopper-details-store';
import { planStore } from './services/plan-store';

export class SplititFlexFields {
    private _createdTimestamp?: Date;
    private _readyTimestamp?: Date;
    private _status: string;
    private _ready: boolean;
    private _showWhenReady: boolean;
    private _commonContainer: HTMLElement;
    private _collectorId: string;
    private _readyCallback: () => void;
    private _onSuccessCallback: (result: any) => void;
    private _on3ds1Callback: (params3d: any) => void;
    private _on3ds1CompleteCallback: (requestedRedirectUrl: string) => void;
    private _fields: Array<FieldWrapper>;
    private _installmentPicker: InstallmentPicker;
    private _termsConditionsLink: TermsConditionsLink;
    private _errorBox: ErrorBox;
    private _paymentButton: PaymentButton;
    private _dataConfigurationTimer: any;
    private _isFormVisible: boolean;
    private _current3dModal?: HTMLElement;
    private _onEventCallback?: (evType: string, oldValue: any, newValue: any) => void;

    public version: string = VERSION;
    
    constructor() {
        try {
            this._createdTimestamp = new Date();
            this._status = 'created';
            this._ready = false;
            this._showWhenReady = false;
            this._fields = [];
            this._dataConfigurationTimer = null;
    
            if (config.validate()) {
                frameManager.init(FrameManagerRoles.HOST);
                state.reset();
                shopperDetailsStore.init();
                planStore.init();
    
                if (!config.container){
                    let stateListener = state.onChange(old => {
                        if (!old.publicToken && state.get().publicToken){
                            state.removeListener(stateListener);
                            this.show();
                        }
                    });
                } else {
                    this.hideInternal();
                }
    
                frameManager.subscribe(MessageTypes.COLLECTOR_INIT, this, this.collectorInit);
                frameManager.subscribe(MessageTypes.COLLECTOR_COMMIT_COMPLETE, this, this.collectorCommitComplete);
    
                this._commonContainer = document.querySelector(config.fields.number.selector).parentElement;
                appendHiddenFrame(this._commonContainer, getCollectorUrl(), FrameNames.COLLECTOR);
    
                state.setCulture(config.culture);
                state.setIsSandbox(FLEX_FIELDS_ENV !== 'production' || config.useSandboxApi);
    
                if (config.publicToken !== null && config.publicToken !== undefined){
                    state.setPublicToken(config.publicToken);
                }
    
                localizer.loadRemote((resources: { [key: string]: string; }) => {
                    if (this._ready){
                        frameManager.notify(MessageTypes.LOCALIZATION_LOADED, <LocalizationPayload>{
                            resources: resources
                        });
                    }
                });
            } else {
                this._status = "ERROR";
                utils.logCustomError("Invalid config", config);
            }
        } catch (e){
            utils.logException(e);
        }
    }

    demo(demoState: DemoState){
        state.setAmount(demoState.amount);
        state.setDemo(demoState);
        return this;
    }

    destroy(){
        utils.trace("Calling destroy()");

        try{
            var collectorFrame = document.querySelector(`iframe[name='${FrameNames.COLLECTOR}']`);
            if (collectorFrame){
                collectorFrame.remove();
            }
    
            if (this._installmentPicker){
                this._installmentPicker.destroy();
            }
    
            if (this._termsConditionsLink){
                this._termsConditionsLink.destroy();
            }
    
            if (this._errorBox){
                this._errorBox.destroy();
            }
    
            if (this._paymentButton){
                this._paymentButton.destroy();
            }
    
            this._fields.forEach(f => {
                f.destroy();
            });
    
            state.reset();
        } catch(e){
            utils.logException(e);
        }
    }

    getStatus() {
        return this._status;
    }

    ready(callback: () => void) {
        if (this._ready) {
            try{
                callback.call(this);
            } catch (e){
                utils.logException(e);
            }
            
        } else {
            this._readyCallback = callback;
        }

        return this;
    }

    hasErrors(){
        if (!state.get().numInstallments){
            return true;
        }

        return !state.get().fields.reduce((p, f) => p && f.isValid == true, true);
    }

    onSuccess(callback: (data: any) => void) {
        this._onSuccessCallback = callback;
        return this;
    }

    onError(callback: (errors: Array<IErrorDescriptor>) => void) {
        state.onChange(old => {
            try{
                let errors = state.getActiveErrors(old);
                if (errors.isChanged){
                    callback.call(this, errors.errors);
                }
            } catch (e){
                utils.logException(e);
            }
        });
        return this;
    }

    reportExternalError(msg: string, data?: any){
        utils.logCustomError(msg, data);
    }

    reportExternalException(ex: any){
        utils.logException(ex);
    }

    onEvent(callback: (evType: string, oldValue: any, newValue: any) => void){
        this._onEventCallback = callback;

        state.onChange(old => {
            try{
                let newState = state.get();

                if (old.numInstallments != newState.numInstallments){
                    callback("numInstallments", old.numInstallments, newState.numInstallments);
                }

                if (old.termsAccepted != newState.termsAccepted){
                    callback("termsAccepted", old.termsAccepted, newState.termsAccepted);
                }

                newState.fields.forEach(f => {
                    var prevData = old.fields.filter(x => x.type == f.type);
                    if (!prevData || prevData.length == 0){
                        if (f.isFocused === true || f.isFocused === false){
                            callback(`${f.type}.focused`, null, f.isFocused);
                        }

                        if (f.isValid === true || f.isValid === false){
                            callback(`${f.type}.valid`, null, f.isValid);
                        }
                    } else {
                        if (prevData[0].isFocused != f.isFocused && (f.isFocused === true || f.isFocused === false)){
                            callback(`${f.type}.focused`, prevData[0].isFocused, f.isFocused);
                        }
                        if (prevData[0].isValid != f.isValid && (f.isValid === true || f.isValid === false)){
                            callback(`${f.type}.valid`, prevData[0].isValid, f.isValid);
                        }
                    }

                    if (f.type == FIELD_TYPES.NUMBER && f.cardType !== undefined){
                        if (!prevData || prevData.length == 0 || prevData[0].cardType != f.cardType){
                            callback(`${f.type}.card`, prevData && prevData.length > 0 ? prevData[0].cardType : null, f.cardType);
                        }
                    }
                })

            } catch (e){
                utils.logException(e);
            }
        });
        return this;
    }

    onFieldUpdate(callback: (fields: Array<FieldDescriptorInfo>) => void){
        state.onChange(old => {
            try{
                let newState = state.get();

                if (old.fields.length != newState.fields.length){
                    callback.call(this, newState.fields);
                } else if (JSON.stringify(old.fields) != JSON.stringify(newState.fields)) {
                    callback.call(this, newState.fields);
                }
            } catch (e){
                utils.logException(e);
            }
        });
        return this;
    }

    onRequire3ds1(callback) {
        this._on3ds1Callback = callback;
        return this;
    }

    on3dComplete(callback) {
        this._on3ds1CompleteCallback = callback;
        return this;
    }

    toggle(){
        if (this._isFormVisible){
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        if (!this._ready){
            this._showWhenReady = true;
            utils.logWarning("Cannot invoke .show() method until plugin has loaded. It's handled and shown when ready, but for better UX, please call .show() on ready() callback.");
            return;
        }

        if (this._isFormVisible){
            return;
        }

        try {
            if (!state.get().publicToken){
                utils.logWarning("Potential missconfiguration: Usually, when calling .show() publicToken should be specified. Either specify public token in setup function or call setPublicToken() explicitely. More info: http://flex-fields.production.splitit.com.");
    
                let stateListener = state.onChange(old => {
                    if (!old.publicToken && state.get().publicToken){
                        state.removeListener(stateListener);
                        this.show();
                    }
                });
            } else {
                this._isFormVisible = true;
    
                // Call GetInitiatedPlan...
                planStore.load();
    
                if (config.container){
                    frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.shown(true));
    
                    var container = document.querySelector(config.container) as HTMLElement;
                    container.style.display = 'block';
                } else {
                    frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.shown(false));
                }
            }
        } catch (e){
            utils.logException(e);
        }
    }

    hide() {
        if (!this._isFormVisible){
            return;
        }
        
        frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.hidden());
        this.hideInternal();
    }

    synchronizePlan(){
        this._installmentPicker.resetForPlanSync();
        planStore.load();
    }

    private hideInternal(){
        this._isFormVisible = false;

        if (config.container){
            var container = document.querySelector(config.container) as HTMLElement;
            container.style.display = 'none';
        }
    }

    setPublicToken(token) {
        state.setPublicToken(token);
        this.verifyDataConfiguration('publicToken', token);
    }

    setInstallmentPlanNumber() {
        utils.logWarning('You are using obsolete method "setInstallmentPlanNumber". Please refer to documentation here: http://flex-fields.production.splitit.com');
    }

    setTermsConditionsUrl() {
        utils.logWarning('You are using obsolete method "setTermsConditionsUrl". Please refer to documentation here: http://flex-fields.production.splitit.com');
    }

    setPrivacyPolicyUrl() {
        utils.logWarning('You are using obsolete method "setPrivacyPolicyUrl". Please refer to documentation here: http://flex-fields.production.splitit.com');
    }

    set(data: any) {
        try{
            if (data.responseHeader) {
                // Directly passed response from /initiate
                if (!data.responseHeader.succeeded) {
                    state.setErrors([{ message: "Unsuccessfull attempt to initiate plan.", errorCode: "CL-0" }]);
                    return;
                }
    
                state.parseResponse(data);
            } else if (data.ResponseHeader) {
                // Directly passed response from /initiate
                if (!data.ResponseHeader.Succeeded) {
                    state.setErrors([{ message: "Unsuccessfull attempt to initiate plan.", errorCode: "CL-0" }]);
                    return;
                }
    
                state.parseResponsePascalCase(data);
            } else {
                state.parseUnknown(data);
            }
    
            this.whatIsWrong();
        } catch (e){
            utils.logException(e);
        }
    }

    private verifyDataConfiguration(name, value){
        if (config.isDebugMode()){
            if (value){
                console.log(`[splitit] FlexFields trace: Setting ${name} to ${value}.`);
            } 

            if (!this._dataConfigurationTimer){
                this._dataConfigurationTimer = setTimeout(() => this.whatIsWrong(), 2000);
            }
        }
    }

    public whatIsWrong() : void {
        if (config.isDebugMode()){
            let st = state.get();
    
            if (!st.publicToken){
                utils.logCustomError(`FlexFields configuration error: Missing publicToken. For more information, see https://flex-fields.production.splitit.com/#key-concepts-supplying-data`);
            }
        }
    }

    public getSessionParams() : any {
        return {
            publicToken: state.get().publicToken,
            planNumber: state.get().ipn,
            refOrderNumber: state.get().refOrderNumber,
            isTermsAccepted: state.get().termsAccepted,
            errorRedirectUrl: state.get().errorRedirectUrl,
            successRedirectUrl: state.get().successRedirectUrl
        };
    }

    public getNumInstallments() : number {
        return state.get().numInstallments;
    }

    public setNumInstallments(num: number) {
        state.setNumInstallments(num);
    }

    checkout() {
        try {
            if (!state.get().termsAccepted){
                state.setErrors([<ModelError>{ errorCode: ErrorCodes.TERMS_NOT_SELECTED, message: localizer.termsNotAccepted }]);
                return;
            }
            frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.checkout());
            frameManager.notify(MessageTypes.TRIGGER_COLLECT, <TriggerCollectPayload>{ collectorId: this._collectorId, commitId: utils.guid() }); 
        } catch(e){
            utils.logException(e);
        }
    }

    redirect3ds(data: any){
        this.redirect3ds1(data);
    }

    redirect3ds1(data: any) {
        try {
            this._current3dModal = render3dModalIframe(<IModal3dsIframeParams>{
                publicToken: state.get().publicToken,
                onComplete: (result) => this.process3dIframeResult(result, data),
                onUserClosed: () => {
                    if (this._onEventCallback){
                        this._onEventCallback('3dsmodal.closed', null, null);
                    }
                }
            });
        } catch(e){
            utils.logException(e);
        }
    }

    private process3dIframeResult(result3d: IModal3dsIframeResult, data: any){
        if (!this._onSuccessCallback){
            utils.logCustomError('3D flow not implemented correctly. When using 3D in iframe, having an onSuccess callback is required.');
            return;
        }

        if (this._on3ds1CompleteCallback){
            this._on3ds1CompleteCallback.call(this, result3d);
        }

        if (result3d.isSuccess){

            if (data?.installmentPlan){
                data.responseHeader = {succeeded: true, errors: [], traceId: `${data.responseHeader?.traceId}-updated`};
            }

            var successRes = {
                secure3dRedirectUrl: result3d?.redirectUrl,
                successRedirectUrl: result3d?.redirectUrl,
                is3d: true,
                data: data
            };

            SplititFlexFields.trimSuccessResponse(successRes);

            this._onSuccessCallback.call(this, successRes);
        } else {
            state.setErrors([{ message: localizer.secure3d_failed, errorCode: "641-F", additionalInfo: result3d?.redirectUrl }]);
        }

        hide3dModal(this._current3dModal);
    }

    public updateDetails(data: IUpdateDetailsData, callback?: () => void) {
        if (data.consumerData || data.billingAddress || data.refOrderNumber){
            if (callback){
                callback.bind(this);
            }
            
            shopperDetailsStore.update(data, callback);
        }
    }

    private collectorInit(data: any){
        try{
            this._collectorId = data._originId;
            this._initFieldFrames();
            
            this._installmentPicker = new InstallmentPicker();
            this._installmentPicker.init();
    
            this._termsConditionsLink = new TermsConditionsLink();
            this._termsConditionsLink.render();
    
            this._errorBox = new ErrorBox();
            this._paymentButton = new PaymentButton();
            this._paymentButton.render();
            this._paymentButton.onClick(() => {
                if (config.paymentButton.onClick){
                    config.paymentButton.onClick.call(this, this._paymentButton, this);
                } else {
                    this.checkout();
                }
            });

            state.onChange(old => {
                let errors = state.getActiveErrors(old);
                if (errors.isChanged && errors.hasErrors){
                    if (errors.hasGeneralErrors){
                        frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.generalErrorShown(errors));
                    }
    
                    let fieldErrors = errors.errors.filter(e => e.fieldTypes != null && e.fieldTypes.length > 0 && e.showError);
                    if (fieldErrors.length > 0){
                        frameManager.notify(MessageTypes.TRACKER_EVENT, TrackerEventPayload.fieldErrorShown(fieldErrors));
                    }
                }
            });

        } catch(e){
            utils.logException(e);
        }
    }

    private collectorCommitComplete(data: any) {
        try{
            let cleanData = Object.assign({}, data);
            delete cleanData._originId;
            delete cleanData._messageType;
    
            if (data.is3ds1 && !this._on3ds1Callback){
                this.redirect3ds1(cleanData);
            } else if (data.is3ds1 && this._on3ds1Callback) {
                this._on3ds1Callback.call(this, cleanData);
            } else {
                if (this._onSuccessCallback) {
                    cleanData.is3d = false;
                    
                    SplititFlexFields.trimSuccessResponse(cleanData);
                    this._onSuccessCallback.call(this, cleanData);
                } 
            }
        } catch(e){
            utils.logException(e);
        }
    }

    private _initFieldFrames() {
        try{
            frameManager.subscribe(MessageTypes.FIELD_INIT, this, (data: FieldInitPayload) => {
                try{
                    this._fields.forEach(f => {
                        if (f.type == data.fieldType){
                            f.init(data);
                        }
                    });
        
                    if (this._fields.every(f => f.isInitialized)) {
                        frameManager.notify(MessageTypes.INTRODUCE_COLLECTOR, { collectorId: this._collectorId });
                        this._ready = true;
                        this._readyTimestamp = new Date();
                        this._status = 'ready';
                        this.initTracking();
                                
                        if (localizer.remoteResources){
                            frameManager.notify(MessageTypes.LOCALIZATION_LOADED, <LocalizationPayload>{
                                resources: localizer.remoteResources
                            });
                        }
        
                        utils.logWarning(`FlexFields v${this.version} status: ${this.getStatus()}`);
        
                        this.updateDetails(<IUpdateDetailsData>{
                            consumerData: config.consumerData,
                            billingAddress: config.billingAddress
                        });

                        if (this._showWhenReady){
                            this.show();
                        }
        
                        if (this._readyCallback) {
                            this._readyCallback.call(this);
                        }
                    }
                } catch (e){
                    utils.logException(e);
                }
            });
    
            this._fields = [];
    
            if (config.fields.number) {
                this._fields.push(new FieldWrapper(
                    config.fields.number.selector,
                    FIELD_TYPES.NUMBER));
            }
    
            if (config.fields.cardholderName) {
                this._fields.push(new FieldWrapper(
                    config.fields.cardholderName.selector,
                    FIELD_TYPES.CARDHOLDER_NAME));
            }
    
            if (config.fields.cvv) {
                this._fields.push(new FieldWrapper(
                    config.fields.cvv.selector,
                    FIELD_TYPES.CVV));
            }
    
            if (config.fields.expirationDate) {
                this._fields.push(new FieldWrapper(
                    config.fields.expirationDate.selector,
                    FIELD_TYPES.EXP_DATE));
            }
    
            if (config.fields.expirationMonth) {
                this._fields.push(new FieldWrapper(
                    config.fields.expirationMonth.selector,
                    FIELD_TYPES.EXP_MONTH));
            }
    
            if (config.fields.expirationYear) {
                this._fields.push(new FieldWrapper(
                    config.fields.expirationYear.selector,
                    FIELD_TYPES.EXP_YEAR));
            }
    
            for (let i = 0; i < this._fields.length; i++) {
                this._fields[i].render();
            }
        } catch(e){
            utils.logException(e);
        }
    }

    private locateUiLayoutRecursive(el: HTMLElement) {
        try{
            if (el.classList.contains('splitit-default-ui')){
                if (el.classList.contains('grouped')){
                    return 'splitit-default-ui.grouped';
                } else {
                    return 'splitit-default-ui';
                }
            } else if(el.classList.contains('splitit-design-classic')){
                return 'splitit-design-classic';
            } else if(el.classList.contains('splitit-design-horizontal')){
                return 'splitit-design-horizontal';
            }
    
            if (el == document.body || el == document.documentElement || 
                !el.parentElement || 
                el.parentElement == document.body || el.parentElement == document.documentElement){
                return 'custom';
            }
    
            return this.locateUiLayoutRecursive(el.parentElement);
        } catch (e){
            utils.logException(e);
        }
    }

    private initTracking(){
        try{
            var data = <TrackerInitPayload>{
                merchantUrl: location.hostname + (location.port ? ':' + location.port : ''),
                uiLayout: this.locateUiLayoutRecursive(this._commonContainer),
                config: config,
                readyAt: this._readyTimestamp,
                createdAt: this._createdTimestamp
            };

            if (data.uiLayout == 'splitit-default-ui.grouped'){
                data.design = 'old.grouped';
            }

            if (data.uiLayout == 'splitit-default-ui'){
                data.design = 'old';
            }

            if (data.uiLayout == 'splitit-design-classic'){
                data.design = 'classic';
            }

            if (data.uiLayout == 'splitit-design-horizontal'){
                data.design = 'horizontal';
            }

            frameManager.notify(MessageTypes.TRACKER_INIT, data);
        } catch (e){
            utils.logException(e);
        }
    }

    public static trimSuccessResponse(successResponse: any){
        let x = successResponse?.data;

        if (x){
            if (x.installmentPlan && !x.installmentPlan.merchant){
                let keysToKeep = ['installmentPlan', 'responseHeader'];

                Object.keys(x).forEach(k => {
                    if (keysToKeep.indexOf(k) == -1){
                        delete x[k];
                    }
                });
            }
        }
    }
};