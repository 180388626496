import { FlexField } from "./field-base";
import { FIELD_TYPES } from "./field-types";
import Cleave from 'cleave.js';
import { CollectCardDataPayload } from "../frame-manager-payloads";
import { localizer } from "../localization-provider";
import {luhnValidate} from 'splitit-utils';
import { utils } from "../utils";
import { FORTER_COOKIE_NAME } from "../constants-ts";

export class CardNumberField extends FlexField {
    private _cardType: string;

    public constructor(guid, uiMode){
        super(guid, uiMode);
       
        this._type = FIELD_TYPES.NUMBER;
    }

    protected formatCollectPayload(payload: CollectCardDataPayload) : CollectCardDataPayload {
        payload = super.formatCollectPayload(payload);
        payload.cardType = this._cardType;
        payload.forter = utils.getCookie(FORTER_COOKIE_NAME);
        return payload;
    }

    public updateChange(newValue: string){
        // Override default change, listen to cleave's
    }

    public updateKeypress(newValue: string){
        // Override default change, listen to cleave's
    }

    protected validate(){
        if (!this._value || this._value.length < 6){
            this._isValid = false;
            this._clientError = localizer.invalidCardNumber;
        } else {
            if (!luhnValidate(this._value)){
                this._isValid = false;
                this._clientError = localizer.invalidCardNumber;
            }
        }
    }

    protected getExposableValue(){
        if (this._value && this._value.length > 6){
            return this._value.substr(0, 6);
        }

        return this._value;
    }

    public init(fieldSelector: string){
        super.init(fieldSelector);

        new Cleave(this._inputElement, {
            creditCard: true,
            creditCardStrictMode: true,
            onValueChanged: (ev) => {
                if (this._value != ev.target.rawValue){
                    this._value = ev.target.rawValue;
                    if (this._value && this._value.length > 0){
                        this._everNonEmpty = true;
                    }
                    this._isValid = null;
                    this._clientError = null;
                    this.sendToCollector();
                }
            },
            onCreditCardTypeChanged: (cardType) => {
                if (this._cardType && this._cardType != ''){
                    this._inputElement.classList.remove(this._cardType);
                }
                if (cardType){
                    this._inputElement.classList.add(cardType);
                }
                this._cardType = cardType.toString();
                this.sendToCollector();
            }
        });
    }
}