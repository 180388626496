import { FLEX_FIELDS_URL } from './constants';
import { FIELD_TYPES } from './fields/field-types';
import { config } from './config';

export const MessageTypes = {
    COLLECTOR_INIT: "COLLECTOR_INIT",
    FIELD_INIT: "FIELD_INIT",
    LOCALIZATION_LOADED: "LOCALIZATION_LOADED",
    TRIGGER_COLLECT: "TRIGGER_COLLECT",
    INTRODUCE_COLLECTOR: "INTRODUCE_COLLECTOR",
    COLLECT_CARD_DATA: "COLLECT_CARD_DATA",
    COLLECTOR_COMMIT_COMPLETE: "COLLECTOR_COMMIT_COMPLETE",
    STATE_SYNC: "STATE_SYNC",
    TRACKER_INIT: "TRACKER_INIT",
    TRACKER_EVENT: "TRACKER_EVENT",
    FIELD_STYLE_OVERRIDE: "FIELD_STYLE_OVERRIDE"
};

export const FrameManagerRoles = {
    HOST: 'host',
    COLLECTOR: 'collector',
    FIELD: 'field'
};

export const FrameNames = {
    COLLECTOR: 'splitit-hf-collector',
    FIELDS: [
        'splitit-hf-' + FIELD_TYPES.NUMBER,
        'splitit-hf-' + FIELD_TYPES.CARDHOLDER_NAME,
        'splitit-hf-' + FIELD_TYPES.CVV,
        'splitit-hf-' + FIELD_TYPES.EXP_DATE,
        'splitit-hf-' + FIELD_TYPES.EXP_MONTH,
        'splitit-hf-' + FIELD_TYPES.EXP_YEAR
    ]
};

class FrameManager {
    private _debug: boolean;
    private _trace: boolean;
    private _role: string;
    private _guid: string;
    private _subscribers: {};

    constructor(){
        this._subscribers = {};

        var $this = this;
        window.addEventListener("message", function (event) {
            try {

                $this._trace && console.log('Received message');
                $this._trace && console.log(event);

                let dataJson = null;

                if (event.data && event.data.startsWith && event.data.startsWith('{')){
                    dataJson = JSON.parse(event.data);
                } 

                if (!dataJson){
                    $this._trace && console.log('Wrong data...');
                    return;
                }

                if (dataJson._messageType && $this._subscribers[dataJson._messageType]) {
                    var shouldReadMessage = true;

                    if (dataJson._destination) {
                        shouldReadMessage = $this._guid == dataJson._destination;
                    }

                    if (shouldReadMessage) {
                        $this._trace && console.log('FrameManager[' + $this._role + '] Message received:');
                        $this._trace && console.log(event);

                        for (let i = 0; i < $this._subscribers[dataJson._messageType].length; i++) {
                            let subscriber = $this._subscribers[dataJson._messageType][i];
                            $this._trace && console.log('Invoking subscriber:');
                            $this._trace && console.log(subscriber.owner);
                            subscriber.callback.call(subscriber.owner, dataJson);
                        }
                    }
                }
            } catch (err) {
                // Do nothing
                $this._debug && console.error(err);
            }
        });
    }

    public init(role:string, guid?:string) {
        this._debug = config.isDebugMode();
        this._trace = false;

        this._role = role;
        this._guid = guid;
        this._subscribers = {};
    }

    subscribe(msg: string, owner: any, callback: (any) => void) {
        if (!this._subscribers[msg]) {
            this._subscribers[msg] = [];
        }

        this._subscribers[msg].push({ owner, callback });
    }

    notify(msg: string, data?: any, destinationId?: string) {
        this._notifyInternal(msg, data, '*', destinationId);
    }

    /**
     * Notifies only splitit frames
     * @param {string} msg Message type
     * @param {any} data Object data
     * @param {string} destinationId Destination id (optional)
     */
    notifySecure(msg: string, data: any, destinationId?: string) {
        this._notifyInternal(msg, data, FLEX_FIELDS_URL, destinationId);
    }

    _notifyInternal(msg, data, targetOrigin, destinationId) {
        if (this._trace){
            console.log('Sending message from ' + this._role);
            console.log(msg);
            console.log(data);
        }
        let payload = Object.assign(data || {}, { _messageType: msg, _destination: destinationId, _originId: this._guid });

        let hostWindow = null;

        if (this._role == FrameManagerRoles.COLLECTOR || this._role == FrameManagerRoles.FIELD) {
            hostWindow = window.parent;
        } else {
            hostWindow = window;
        }

        let destinations = [];
        if (targetOrigin == '*') {
            destinations.push(hostWindow);

            for (let i = 0; i < hostWindow.frames.length; i++) {
                destinations.push(hostWindow.frames[i]);
            }
        } else {
            destinations.push(hostWindow.frames[FrameNames.COLLECTOR]);

            for (let i = 0; i < FrameNames.FIELDS.length; i++) {
                try {
                    let d = hostWindow.frames[FrameNames.FIELDS[i]];
                    d && destinations.push(d);
                } catch (_) {
                    // do nothing
                }
            }
        }

        for (let i = 0; i < destinations.length; i++) {
            if (destinations[i] == window)
                continue;

            try {
                destinations[i].postMessage(JSON.stringify(payload), targetOrigin);
            } catch (err) {
                this._trace && console.error(err);
            }
        }
    }
}

let frameManager = new FrameManager();
export {frameManager};