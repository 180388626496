import { FLEX_FIELDS_ENV } from './constants';
import { utils } from "./utils";
import { frameManager, MessageTypes } from "./frame-manager";
import { LocalizationPayload } from "./frame-manager-payloads";
import * as splititApi from 'splitit-sdk';
import { config } from './config';
import { SystemTextCategory } from 'splitit-sdk';
import { apiFactory } from './api-factory';
import { FIELD_TYPES } from './fields/field-types';
import { UI_MODE_HORIZONTAL } from './constants-ts';

class FormattedLocalization {
    paymentOrd: string = "Payment {cur}/{total}";
    numPayments: string = "{num} payments";
    amountPerMonth: string = "{currencySymbol}{amount} per month";
    amountNow: string = "{currencySymbol}{amount} + ";

    _keys: Array<string> = [
        "paymentOrd", "numPayments", "amountPerMonth"
    ];
}

class ApiResources {
    

    _keys: Array<string> = [
        "invalidExpirationDate", "invalidcvv", "invalidCardNumber"
    ];
}

export class Localization {
    paymentSchedule: string = "Payment schedule";
    expirationDatePlaceholder: string = "Exp. MM/YY";
    expirationYearPlaceholder: string = "YY";
    expirationMonthPlaceholder: string = "MM";
    pay: string = "Place Order";
    charge: string = "Charge";
    held: string = "Held";
    howSplititWorks: string = "How Splitit Works";
    termsConditionsHtml: string = "I accept the <a class='splitit-tc-t'>Terms & Conditions</a> and <a class='splitit-tc-p'>Privacy Policy</a>";
    modal3dTitle: string = "3D redirection required";
    modal3dContent: string = "To complete the payment, you will be redirected to your account provider for additional 3D security.";
    ok: string = "OK";
    cancel: string = "Cancel";
    requiredCredit: string = "Required Credit";
    secure3ds1_title: string = "Your card issuer needs to authenticate your payment request for added security";
    secure3ds1_subtitle: string = "Once verified your order will be complete";
    secure3ds1_youWillBeRedirectedIn: string = "You will be redirected in";
    seconds: string = "seconds";
    redirect: string = "Redirect";
    formatted: FormattedLocalization;
    apiResources: ApiResources;
    secure3d_userCancelled: string = "3D secure verification cancelled by user.";
    secure3d_failed: string = "3D secure verification was not completed successfully.";
    onePayment: string = "1 payment";
    termsNotAccepted: string = "Please read and accept terms and conditions before proceeding.";
    invalidExpirationDate: string = "Invalid expiration date.";    
    invalidcvv: string = "Invalid CVV.";
    invalidCardNumber: string = "Invalid credit card number.";
    invalidCardholderName: string = "Name on card cannot be empty.";

    private _onChangeCallbacks: Array<() => void>;

    _keys: Array<string> = [
        "paymentSchedule", "expirationDatePlaceholder", 
        "expirationYearPlaceholder", "expirationMonthPlaceholder", 
        "pay", "termsConditionsHtml", "charge", "held", "howSplititWorks",
        "modal3dTitle", "modal3dContent", "ok", "cancel",
        "requiredCredit", "secure3ds1_title", "secure3ds1_subtitle",
        "secure3ds1_youWillBeRedirectedIn", "seconds", "redirect",
        "formatted", "secure3d_userCancelled", "secure3d_failed",
        "onePayment", "termsNotAccepted",
        "invalidExpirationDate", "invalidcvv", "invalidCardNumber", "invalidCardholderName"
    ];

    public remoteResources?: { [key: string]: string; };

    constructor(){
        this._onChangeCallbacks = [];
        this.formatted = new FormattedLocalization();
        this.apiResources = new ApiResources();
        this.remoteResources = null;

        this['lbl-' + FIELD_TYPES.CVV] = "CVV";
        this['lbl-' + FIELD_TYPES.CARDHOLDER_NAME] = "Name on card";
        this['lbl-' + FIELD_TYPES.EXP_DATE] = "MM/YY";
        this['lbl-' + FIELD_TYPES.EXP_MONTH] = "MM";
        this['lbl-' + FIELD_TYPES.EXP_YEAR] = "YY";
        this['lbl-' + FIELD_TYPES.NUMBER] = "Card Number";

        this['tooltip-' + FIELD_TYPES.CVV] = "CVV";
        this['tooltip-' + FIELD_TYPES.CARDHOLDER_NAME] = "Name on card";
        this['tooltip-' + FIELD_TYPES.EXP_DATE] = "MM/YY";
        this['tooltip-' + FIELD_TYPES.EXP_MONTH] = "MM";
        this['tooltip-' + FIELD_TYPES.EXP_YEAR] = "YY";
        this['tooltip-' + FIELD_TYPES.NUMBER] = "Card Number";

        this['placeholder-' + FIELD_TYPES.CVV] = "CVV";
        this['placeholder-' + FIELD_TYPES.CARDHOLDER_NAME] = "Name on card";
        this['placeholder-' + FIELD_TYPES.EXP_DATE] = "MM/YY";
        this['placeholder-' + FIELD_TYPES.EXP_MONTH] = "MM";
        this['placeholder-' + FIELD_TYPES.EXP_YEAR] = "YY";
        this['placeholder-' + FIELD_TYPES.NUMBER] = "Card Number";

        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CVV] = "CVV";
        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CARDHOLDER_NAME] = "Name on card";
        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_DATE] = "MM/YY";
        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_MONTH] = "MM";
        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_YEAR] = "YY";
        this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.NUMBER] = "Card Number";

        this['placeholder-focused-' + FIELD_TYPES.CVV] = "xxx or xxxx";
        this['placeholder-focused-' + FIELD_TYPES.CARDHOLDER_NAME] = "Ex: John Doe";
        this['placeholder-focused-' + FIELD_TYPES.EXP_DATE] = "Ex: 03/24";
        this['placeholder-focused-' + FIELD_TYPES.EXP_MONTH] = "Ex: 02";
        this['placeholder-focused-' + FIELD_TYPES.EXP_YEAR] = "Ex: 24";
        this['placeholder-focused-' + FIELD_TYPES.NUMBER] = "xxxx xxxx xxxx xxxx";

        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CVV] = "xxxx";
        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CARDHOLDER_NAME] = "Ex: John Doe";
        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_DATE] = "MM/YY";
        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_MONTH] = "MM";
        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_YEAR] = "YY";
        this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.NUMBER] = "xxxx xxxx xxxx xxxx";

        frameManager.subscribe(MessageTypes.LOCALIZATION_LOADED, this, (data: LocalizationPayload) => {
            this.assignResources(data.resources);
        });
    }

    public getLabelForField(fieldType: string) {
        return this['lbl-' + fieldType];
    }

    public getTooltipForField(fieldType: string) {
        return this['tooltip-' + fieldType];
    }

    public getPlaceholderForField(fieldType: string, isFocused: boolean, uiMode?: string) {
        let prefix = 'placeholder-';

        if (isFocused){
            prefix = `${prefix}focused-`;
        }

        if (uiMode && uiMode.length > 0 && uiMode != 'null'){
            prefix = `${prefix}${uiMode}-`;
        }

        return this[`${prefix}${fieldType}`];
    }

    public register(callback: (() => void)){
        this._onChangeCallbacks.push(callback);
        callback();
    }

    public assignResources(resources: { [key: string]: any; }) : void {
        this._keys.forEach(p => {
            if (resources[p.toLowerCase()]){
                this[p] = resources[p.toLowerCase()];
            }
        });

        this['tooltip-' + FIELD_TYPES.NUMBER] = resources['pf_card_number_tooltip'];
        this['tooltip-' + FIELD_TYPES.EXP_DATE] = resources['pf_expiration_tooltip'];
        this['tooltip-' + FIELD_TYPES.CVV] = resources['pf_cvv_tooltip'];

        this.formatted._keys.forEach(p => {
            if (resources[p.toLowerCase()]){
                this.formatted[p] = resources[p.toLowerCase()];
            }
        });

        this.apiResources._keys.forEach(p => {
            if (resources[p.toLowerCase()]){
                this.apiResources[p] = resources[p.toLowerCase()];
            }
        });

        this._onChangeCallbacks.forEach(callback => callback());
    }

    loadRemote(callback: (resources: { [key: string]: string; }) => void) {
        const requestData: splititApi.InfrastructureGetResources2Request = {
            cultureName: config.culture,
            touchPointCode: "FlexFields",
            systemTextCategories: [
                SystemTextCategory.FlexFields,
                SystemTextCategory.Error,
                SystemTextCategory.PaymentFormV3
            ]
        };

        let $this = this;

        let infrastructureApi = apiFactory.getInfrastructureApi();
        infrastructureApi.infrastructureGetResources2(requestData)
            .then(result => {
                $this.remoteResources = Object.assign(
                    result.resourcesGroupedByCategories[SystemTextCategory.Error], 
                    result.resourcesGroupedByCategories[SystemTextCategory.FlexFields],
                    result.resourcesGroupedByCategories[SystemTextCategory.PaymentFormV3]);

                $this.assignResources($this.remoteResources);
                
                if (callback){
                    callback($this.remoteResources);
                }

            }).catch(err => {
                console.error(err);
                utils.logCustomError("Unable to fetch resources. Refer to network tab in developer tools, and provide the information from call to GetResources when contacting Splitit support team.");
            });
    }
    
    public paymentOrd(cur: number, total: number) : string {
        return utils.formatString(this.formatted.paymentOrd, {cur, total});
    }

    public numPayments(num: number) : string {
        if (num == 1){
            return this.onePayment;
        }
        
        return utils.formatString(this.formatted.numPayments, {num});
    }

    public amountPerMonth(currencySymbol: string, amount: number, decimalPlaces: number){
        return utils.formatString(this.formatted.amountPerMonth, {currencySymbol, amount: utils.formatDecimalToLocale(amount, decimalPlaces)});
    }

    public amountNow(currencySymbol: string, amount: number, decimalPlaces: number){
        return utils.formatString(this.formatted.amountNow, {currencySymbol, amount: utils.formatDecimalToLocale(amount, decimalPlaces)});
    }
}

const localizer = new Localization();
export { localizer }