import { InstallmentPickerRenderer, IPaymentCalculation } from "./base";
import { localizer } from "../../localization-provider";
import { config } from "../../config";
import { utils } from "../../utils";
import { state } from "../../state";

export class InstallmentPickerSliderRenderer extends InstallmentPickerRenderer {
    private _sliderStops: Array<number>;
    private _sliderValuesWrapper: HTMLElement;
    private _slider: HTMLElement;
    private _isMoving: boolean;
    private _handlePosition: number;
    private _sliderProgress: HTMLElement;
    private _skipPoint: number;
    private _sliderHandle: HTMLElement;
    private _amountSpan: HTMLElement;
    private _nowSpan: HTMLElement;
    private _numPaymentsSpan: HTMLElement;
    private _tmpSelectedValue: number;

    private setSliderValue(newValue){
        this._tmpSelectedValue = newValue;

        var paymentCalc = this.getPaymentCalculation(newValue);
        let valueStr = utils.formatDecimalToLocale(paymentCalc.monthly, state.getCurrencyDecimalPlaces());

        if (paymentCalc.amountNow > 0){
            this._nowSpan.innerText = `+ ${utils.formatCurrency(paymentCalc.amountNow, state.getCurrencySymbol(), state.getCurrencyDecimalPlaces())}`;
        } else {
            this._nowSpan.innerText = "";
        }

        this._amountSpan.innerText = `${state.getCurrencySymbol()}${valueStr}`;
        this._numPaymentsSpan.innerText = paymentCalc.amountNow > 0 ? `x${newValue-1}` : `x${newValue}`;

        this._sliderValuesWrapper.querySelectorAll('.splitit-ip-s-values-item').forEach((el, idx) => {
            if (this.options[idx] == newValue){
                el.classList.add('selected');
            } else {
                el.classList.remove('selected');
            }
        });
    }

    public render(container: HTMLElement){
        container.classList.add("splitit-installment-picker-slider");

        let sliderOptions = this.options.map(o => {
            let widthNum = (100 / this.options.length - 0.01);

            return {
                numPayments: o,
                width: widthNum.toFixed(2),
                widthNumber: widthNum
            };
        });

        let template = require('./slider.t.html');
        container.innerHTML = template({ options: sliderOptions });

        this._nowSpan = container.querySelector(".splitit-ip-s-header-amount-now") as HTMLElement;
        this._amountSpan = container.querySelector(".splitit-ip-s-header-amount") as HTMLElement;
        this._numPaymentsSpan = container.querySelector(".splitit-ip-s-header-num-payments") as HTMLElement;
        this._slider = container.querySelector(".splitit-ip-s-line") as HTMLElement;
        var clickableElements = [this._slider];
        container.querySelectorAll(".splitit-ip-s-line-click-area").forEach(p => clickableElements.push(p as HTMLElement));

        this._sliderHandle = container.querySelector(".splitit-ip-s-line-handle") as HTMLElement;
        this._sliderProgress = container.querySelector(".splitit-ip-s-line-progress") as HTMLElement;
        this._sliderValuesWrapper = container.querySelector(".splitit-ip-s-values") as HTMLElement;
        clickableElements.push(this._sliderValuesWrapper);

        this.refresh();

        clickableElements.forEach(el => el.addEventListener("mousedown", (event) => {
            event.preventDefault();
            this._isMoving = true;
            this._handlePosition = event.pageX - this._slider.getBoundingClientRect().left;
            this.updateHandle();
        }));

        window.addEventListener("mousemove", (event) => {
            if (this._isMoving) {
                this._handlePosition = event.pageX - this._slider.getBoundingClientRect().left;
                this._handlePosition = Math.min(Math.max(this._handlePosition, 0), this._slider.offsetWidth);
                this.updateHandle();
            }
        });

        window.addEventListener("mouseup", (event) => {
            if (this._isMoving){
                this.setSelectedValue(this._tmpSelectedValue);
            }
            this._isMoving = false;
        });

        if (this.initialSelection){
            this.setSelectedValue(this.initialSelection);
            var idx = sliderOptions.findIndex(val => val.numPayments == this.initialSelection);
            if (idx > -1){
                this._handlePosition = (idx + 0.5) * sliderOptions[0].widthNumber / 100 * this._slider.offsetWidth;
                this.updateHandle();
            }
        }
    }

    public refresh(){
        this.calculateSliderStops();
        this.updateHandle();
        this.setSelectedValue(this._tmpSelectedValue);
    }

    private updateHandle(){
        let idx = -1;
        let relativePosition = this._handlePosition == null ? 50 : this._handlePosition / this._slider.offsetWidth * 100;

        this._sliderStops.forEach((stop, i) => {
            if (relativePosition >= stop - this._skipPoint) {
                idx = i;
            }
        })

        let stop = this._sliderStops[idx];
        this._sliderHandle.style.left = stop + "%";
        this._sliderProgress.style.width = (stop) + "%";
        this.setSliderValue(this.options[idx]);
    }

    private calculateSliderStops(){
        this._isMoving = false;
        this._handlePosition = null;
        this._sliderStops = [];

        let fraction = 100 / (this.options.length - 1);
        for (let i = 0; i < this.options.length; i++) {
            this._sliderStops.push(fraction * i);
        };
        this._skipPoint = fraction/2;
        this._sliderValuesWrapper.style.marginLeft = `-${fraction/2}%`;
        this._sliderValuesWrapper.style.marginRight = `-${fraction/2}%`;
    }

    protected getPickerType() : string{
        return "slider";
    }
}